export default class pgFunctions {
    constructor (
        oid, 
        proname,
        description
    ) {
      this.oid = oid
      this.proname = proname
      this.description = description
    }
  }