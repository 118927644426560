export default {
    state: {
      loading: false,
      loading2: false,
      error: null
    },
    mutations: {
      setLoading (state, payload) {
        state.loading = payload
      },
      setLoading2 (state, payload) {
        state.loading2 = payload
      },
      setError (state, payload) {
        state.error = payload
      },
      clearError (state) {
        state.error = null
      }
    },
    actions: {
      // Show loading
      setLoading ({commit}, payload) {
        commit('setLoading', payload)
      },
      setLoading2 ({commit}, payload) {
        commit('setLoading2', payload)
      },
      // Show error
      setError ({commit}, payload) {
        commit('setError', payload)
      },
      // Clear error
      clearError ({commit}) {
        commit('clearError')
      }
    },
    getters: {
      // Get load status
      loading (state) {
        //console.log(state.loading)
        return state.loading
      },
      loading2 (state) {
        //console.log(state.loading)
        return state.loading2
      },
      // Get error message
      error (state) {
        return state.error
      }
    }
  }