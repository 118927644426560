<template>
    <v-alert dense  text   type="info"  dismissible v-model="warningZone" class="pa-1 ma-1">
            Внимание! Мы заметили отсутствие активности в системе. Через минуту произойдет автоматическое отключение.
    </v-alert>
</template>

<script>

import Vue from 'vue'
export default {

    name: "AutoLogout",

    data: function() {
        return {
            events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],
            eventsM: ['click'],

            warningTimer: null,
            logoutTimer: null,
            warningZone: false,
        }
    },

    created () {    
        //window.addEventListener('beforeunload', this.removeUser)  

        /* console.log('Проверка пользователя - жив? ' + Vue.ls.get(this.$store.getters.UserMail));

        if(Vue.ls.get(this.$store.getters.UserMail) == null)
        {
            this.$store.dispatch('logoutUser')
            this.$router.push('/')
        } */
    },

    mounted() {
        this.events.forEach(function(event) {
            window.addEventListener(event, this.resetTimer);
        }, this);
        
        

        this.setTimers();
  
    },

    destroyed() {
        this.events.forEach(function(event) {
            window.addEventListener(event, this.resetTimer);
        }, this);

        this.setTimers();
    },

    methods: {
        setTimers: function() {
            if(this.$store.getters.checkUser){
                this.warningTimer = setTimeout(this.warningMessage, 14 * 60 * 1000); //14 minutes - 14 * 60 * 1000
                this.logoutTimer = setTimeout(this.logoutUser, 15 * 60 * 1000); //15 minutes - 15 * 60 * 1000
                Vue.ls.set(this.$store.getters.UserMail, 'РАБОТАЕТ', 15 * 60 * 1000); //expiry 15 minutes
                //console.log(111)
                this.warningZone = false;
                //this.$store.commit('SetPanelMini', true)
            }
        },

        warningMessage: function() {
            this.warningZone = true;
        },

        logoutUser: function() {
            this.$store.dispatch('logoutUser')
            Vue.ls.remove(this.$store.getters.UserMail)
            this.$router.push('/')
        },

        resetTimer: function() {
            clearTimeout(this.warningTimer);
            clearTimeout(this.logoutTimer);

            

            this.setTimers();
        },

       

        removeUser: function() {
            //console.log('REMOVE USER')
            this.$store.dispatch('logoutUser')
            this.$router.push('/')
        }
    }
    
}
</script>

<style scoped>

</style>
