import axios from 'axios'
//import router from '../router/router.js'
import otherProjects from './otherProjects_help'
import store from './store.js'
import router from '../router/router.js'
import * as sha512 from 'js-sha512'

export default {
    state: {
        otherProjects: []
    },
    mutations: {
        loadotherProjects (state, payload) {
            state.otherProjects = Array.isArray(payload) ? payload : [];
        }
    },
    actions: {
        async loadotherProjects ({commit}) {
            //console.log('strCronProjectUUID');
            commit('clearError')
            commit('setLoading2', true)

            //const dtStart = date.format(dtStart, 'YYYY-MM-DD');
            //const dtEnd = date.format(dtEnd, 'YYYY-MM-DD');
            const otherProjectsArr = []

            // Текущая дата и время
            let now = new Date();

            let shacd1 = sha512.sha512(formatDateCD(now));
            let shacd2 = sha512.sha512(formatDateCD(getDateMinusMinutes(now, 1)));
            let shacd3 = sha512.sha512(formatDateCD(getDateMinusMinutes(now, 2)));
            let shacd4 = sha512.sha512(formatDateCD(getDateMinusMinutes(now, 3)));
            let shacd5 = sha512.sha512(formatDateCD(getDateMinusMinutes(now, 4)));


            
            let dataTr = 
                    [ { 
                        "cd1":shacd1,
                        "cd2":shacd2,
                        "cd3":shacd3,
                        "cd4":shacd4,
                        "cd5":shacd5
                    } ]
            
            await axios({
                method: 'POST',
                headers: {
                    'api_key': store.state.CurrentApiKey,
                    'Content-Type': 'application/json'
                },
                url: store.state.urlCJ + 'getOtherProjectsFromDB',
                data: dataTr

            })
            .then(async (response) => {
                
                //console.log(response.data)

                const pgSQLData = response.data;

                Object.keys(pgSQLData).forEach(key => {
                    const s = pgSQLData[key]
                    //console.log(s.dtDateLastPay)
                    let dtDateLastPayGet = s.dtDateLastPay == null ? null : formatDate(s.dtDateLastPay)
                    let dtDateAccessGet =  s.dtDateAccess == null ? null : formatDate(s.dtDateAccess)
                    //console.log(dtDateLastPayGet)
                    //console.log(dtDateAccessGet)
                    //console.log(s)
                    otherProjectsArr.push(
                        new otherProjects(
                            s.id, 
                            s.strUserName.trim(),
                            s.intAdmin,
                            s.intLiteAccess,
                            s.intProAccess,
                            s.intUnlimitedAccess,
                            dtDateLastPayGet,
                            dtDateAccessGet,
                            s.strProjectName,
                            s.strProjectUUID
                        ) 
                    )
                        
                })
                //console.log(otherProjectsArr)

                
                commit('loadotherProjects', otherProjectsArr)
                commit('setLoading2', false)
            })
            .catch((response) => {
                //console.log(response)
                var err = Object.assign({}, response)
                console.log('error', err.response);
                                
                commit('loadotherProjects', [])
                commit('setLoading2', false)
                commit('setError', response)
                if(err.response.status == '488')
                {
                    router.push('/')
                }
                
                
            });

        },


        async loadotherProjectsById ({commit}, { id }) {
            return new Promise((resolve, reject) => {
                commit('clearError')
                commit('setLoading2', true)

                // Текущая дата и время
                let now = new Date();

                let shacd1 = sha512.sha512(formatDateCD(now));
                let shacd2 = sha512.sha512(formatDateCD(getDateMinusMinutes(now, 1)));
                let shacd3 = sha512.sha512(formatDateCD(getDateMinusMinutes(now, 2)));
                let shacd4 = sha512.sha512(formatDateCD(getDateMinusMinutes(now, 3)));
                let shacd5 = sha512.sha512(formatDateCD(getDateMinusMinutes(now, 4)));


                
                let dataTr = 
                        [ { 
                            "id":id,
                            "cd1":shacd1,
                            "cd2":shacd2,
                            "cd3":shacd3,
                            "cd4":shacd4,
                            "cd5":shacd5
                        } ]
                
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': store.state.CurrentApiKey,
                        'Content-Type': 'application/json'
                    },
                    url: store.state.urlCJ + 'getOtherProjectsFromDBById',
                    data: dataTr

                })
                .then(async (response) => {
                    
                    //console.log(response.data)

                    const pgSQLData = response.data;

                    Object.keys(pgSQLData).forEach(key => {
                        const s = pgSQLData[key]
                        let dtDateLastPayGet = s.dtDateLastPay == null ? null : formatDate(s.dtDateLastPay)
                        let dtDateAccessGet =  s.dtDateAccess == null ? null : formatDate(s.dtDateAccess)
                        //console.log(s)
                        store.commit('SetLiteAccess', s.intLiteAccess)
                        store.commit('SetProAccess', s.intProAccess)
                        store.commit('SetUnlimitedAccess', s.intUnlimitedAccess)
                        store.commit('SetDateLastPay', dtDateLastPayGet)
                        store.commit('SetDateAccess', dtDateAccessGet)     
                    })
                    //console.log(otherProjectsArr)

                    
                    commit('setLoading2', false)
                    resolve(response);
                })
                .catch((response) => {
                    console.log(response.message)

                    commit('setLoading2', false)
                    commit('setError', response.message)
                    reject(response)
                });
            })

        },
        

        async addotherProjects ({commit}, { un, p, intAdmin, intLiteAccess, intProAccess, intUnlimitedAccess, dtDateLastPay, dtDateAccess, strProjectName }) {
            //console.log('strCronProjectUUID');
            return new Promise((resolve, reject) => {
                commit('clearError')
                commit('setLoading2', true)

                let shaPassword = sha512.sha512(p);

                // Текущая дата и время
                let now = new Date();

                let shacd1 = sha512.sha512(formatDateCD(now));
                let shacd2 = sha512.sha512(formatDateCD(getDateMinusMinutes(now, 1)));
                let shacd3 = sha512.sha512(formatDateCD(getDateMinusMinutes(now, 2)));
                let shacd4 = sha512.sha512(formatDateCD(getDateMinusMinutes(now, 3)));
                let shacd5 = sha512.sha512(formatDateCD(getDateMinusMinutes(now, 4)));

                //console.log(dtDateLastPay)
                
                let dataTr = 
                    [ { 
                        "un":un, 
                        "p":shaPassword,
                        "intAdmin":intAdmin,
                        "intLiteAccess":intLiteAccess,
                        "intProAccess":intProAccess,
                        "intUnlimitedAccess":intUnlimitedAccess,
                        "dtDateLastPay":dtDateLastPay,
                        "dtDateAccess":dtDateAccess,
                        "strProjectName":strProjectName,
                        "cd1":shacd1,
                        "cd2":shacd2,
                        "cd3":shacd3,
                        "cd4":shacd4,
                        "cd5":shacd5
                    } ]
                
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': store.state.CurrentApiKey,
                        'Content-Type': 'application/json'
                    },
                    url: store.state.urlCJ + 'otherProjectsAddNewDB',
                    data: dataTr

                })
                .then(async (response) => {
                    //console.log(response)
                    
                    commit('setLoading2', false)
                    resolve(response);
                })
                .catch((response) => {
                    console.log(response.message)

                    commit('setLoading2', false)
                    commit('setError', response.message)
                    reject(response)
                });
            })
        },

        async updateOtherProject ({commit}, { id, intAdmin, intLiteAccess, intProAccess, intUnlimitedAccess, dtDateLastPay, dtDateAccess }) {
            //console.log('strCronProjectUUID');
            return new Promise((resolve, reject) => {
                commit('clearError')
                commit('setLoading2', true)

                // Текущая дата и время
                let now = new Date();

                let shacd1 = sha512.sha512(formatDateCD(now));
                let shacd2 = sha512.sha512(formatDateCD(getDateMinusMinutes(now, 1)));
                let shacd3 = sha512.sha512(formatDateCD(getDateMinusMinutes(now, 2)));
                let shacd4 = sha512.sha512(formatDateCD(getDateMinusMinutes(now, 3)));
                let shacd5 = sha512.sha512(formatDateCD(getDateMinusMinutes(now, 4)));

                
                let dataTr = 
                    [ { 
                        "id":id, 
                        "intAdmin":intAdmin,
                        "intLiteAccess":intLiteAccess,
                        "intProAccess":intProAccess,
                        "intUnlimitedAccess":intUnlimitedAccess,
                        "dtDateLastPay":dtDateLastPay,
                        "dtDateAccess":dtDateAccess,
                        "cd1":shacd1,
                        "cd2":shacd2,
                        "cd3":shacd3,
                        "cd4":shacd4,
                        "cd5":shacd5
                    } ]
                
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': store.state.CurrentApiKey,
                        'Content-Type': 'application/json'
                    },
                    url: store.state.urlCJ + 'otherProjectsUpdateDB',
                    data: dataTr

                })
                .then(async (response) => {
                    //console.log(response)
                    
                    commit('setLoading2', false)
                    resolve(response);
                })
                .catch((response) => {
                    console.log(response.message)

                    commit('setLoading2', false)
                    commit('setError', response.message)
                    reject(response)
                });
            })
        },

        async deleteotherProjects ({commit}, { id }) {
            //console.log('strCronProjectUUID');
            return new Promise((resolve, reject) => {
                commit('clearError')
                commit('setLoading2', true)

                // Текущая дата и время
                let now = new Date();

                let shacd1 = sha512.sha512(formatDateCD(now));
                let shacd2 = sha512.sha512(formatDateCD(getDateMinusMinutes(now, 1)));
                let shacd3 = sha512.sha512(formatDateCD(getDateMinusMinutes(now, 2)));
                let shacd4 = sha512.sha512(formatDateCD(getDateMinusMinutes(now, 3)));
                let shacd5 = sha512.sha512(formatDateCD(getDateMinusMinutes(now, 4)));

                
                let dataTr = 
                    [ { 
                        "id":id, 
                        "cd1":shacd1,
                        "cd2":shacd2,
                        "cd3":shacd3,
                        "cd4":shacd4,
                        "cd5":shacd5
                    } ]
                
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': store.state.CurrentApiKey,
                        'Content-Type': 'application/json'
                    },
                    url: store.state.urlCJ + 'otherProjectsDeleteFromDB',
                    data: dataTr

                })
                .then(async (response) => {
                    //console.log(response)
                    
                    commit('setLoading2', false)
                    resolve(response);
                })
                .catch((response) => {
                    console.log(response.message)

                    commit('setLoading2', false)
                    commit('setError', response.message)
                    reject(response)
                });
            })
        },

        async changeOtherProjectPassword ({commit}, { projectId, oldPassword, newPassword }) {
            //console.log('strCronProjectUUID');
            return new Promise((resolve, reject) => {
                commit('clearError')
                commit('setLoading2', true)

                let shaPasswordOld = sha512.sha512(oldPassword);
                let shaPasswordNew = sha512.sha512(newPassword);

                // Текущая дата и время
                let now = new Date();

                let shacd1 = sha512.sha512(formatDateCD(now));
                let shacd2 = sha512.sha512(formatDateCD(getDateMinusMinutes(now, 1)));
                let shacd3 = sha512.sha512(formatDateCD(getDateMinusMinutes(now, 2)));
                let shacd4 = sha512.sha512(formatDateCD(getDateMinusMinutes(now, 3)));
                let shacd5 = sha512.sha512(formatDateCD(getDateMinusMinutes(now, 4)));

                //console.log(dtDateLastPay)
                
                let dataTr = 
                    [ { 
                        "id":projectId, 
                        "po":shaPasswordOld,
                        "pn":shaPasswordNew,
                        "cd1":shacd1,
                        "cd2":shacd2,
                        "cd3":shacd3,
                        "cd4":shacd4,
                        "cd5":shacd5
                    } ]
                
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': store.state.CurrentApiKey,
                        'Content-Type': 'application/json'
                    },
                    url: store.state.urlCJ + 'otherProjectsChangePwd',
                    data: dataTr

                })
                .then(async (response) => {
                    //console.log(response)
                    
                    commit('setLoading2', false)
                    resolve(response);
                })
                .catch((response) => {
                    console.log(response.message)

                    commit('setLoading2', false)
                    commit('setError', response.message)
                    reject(response)
                });
            })
        },
        

        
        
    },
    
    getters: {
        otherProjects (state) {
            return state.otherProjects
        }
    }

    
}

function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

function formatDateCD(date) {
    return date.getUTCFullYear() + '-' + 
      String(date.getUTCMonth() + 1).padStart(2, '0') + '-' +
      String(date.getUTCDate()).padStart(2, '0') + ' ' +
      String(date.getUTCHours()).padStart(2, '0') + ':' +
      String(date.getUTCMinutes()).padStart(2, '0');
  }

function getDateMinusMinutes(date, minutes) {
    return new Date(date.getTime() - minutes * 60000);
  }