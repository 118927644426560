export default class pgFunctions {
    constructor (
        oid, 
        view_name,
        comment
    ) {
      this.oid = oid
      this.view_name = view_name
      this.comment = comment
    }
  }