import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/store'

import loginForm from '../views/loginForm.vue'
import mainScreen from '../views/mainScreen.vue'
import cronJobList from '../views/cronJobList.vue'
import cronJobCreateNew from '../views/cronJobCreateNew.vue'
import createSystemJob from '../views/createSystemJob.vue'
import createSystemApiJob from '../views/createSystemApiJob.vue'
import createSystemPayJob from '../views/createSystemPayJob.vue'
import createGC_Sheet_Deals_Exp from '../views/createGC_Sheet_Deals_Exp.vue'
import createGC_Sheet_Payments_Exp from '../views/createGC_Sheet_Payments_Exp.vue'
import createGC_Sheet_Users_Exp from '../views/createGC_Sheet_Users_Exp.vue'
import createBZ365_Sheet_Webinar_Exp from '../views/createBZ365_Sheet_Webinar_Exp.vue'
import createGC_Sheet_Deals_Exp_Edit_Copy from '../views/createGC_Sheet_Deals_Exp_Edit_Copy.vue'
import createGC_Sheet_Payments_Exp_Edit_Copy from '../views/createGC_Sheet_Payments_Exp_Edit_Copy.vue'
import createGC_Sheet_Users_Exp_Edit_Copy from '../views/createGC_Sheet_Users_Exp_Edit_Copy.vue'
import createBZ365_Sheet_Webinar_Exp_Edit_Copy from '../views/createBZ365_Sheet_Webinar_Exp_Edit_Copy.vue'
import createGC_PG_DB_Deals_Exp from '../views/createGC_PG_DB_Deals_Exp.vue'
import createGC_PG_DB_Deals_Exp_Edit_Copy from '../views/createGC_PG_DB_Deals_Exp_Edit_Copy.vue'
import createGC_PG_DB_Payments_Exp from '../views/createGC_PG_DB_Payments_Exp.vue'
import createGC_PG_DB_Payments_Exp_Edit_Copy from '../views/createGC_PG_DB_Payments_Exp_Edit_Copy.vue'
import createGC_PG_DB_Users_Exp from '../views/createGC_PG_DB_Users_Exp.vue'
import createGC_PG_DB_Users_Exp_Edit_Copy from '../views/createGC_PG_DB_Users_Exp_Edit_Copy.vue'
import createBZ365_PG_DB_Webinar_Exp from '../views/createBZ365_PG_DB_Webinar_Exp.vue'
import createBZ365_PG_DB_Webinar_Exp_Edit_Copy from '../views/createBZ365_PG_DB_Webinar_Exp_Edit_Copy.vue'
import createPG_Sheet_Exp from '../views/createPG_Sheet_Exp.vue'
import createPG_Sheet_Exp_Edit_Copy from '../views/createPG_Sheet_Exp_Edit_Copy.vue'
import createFX_PG_Run from '../views/createFX_PG_Run.vue'
import createFX_PG_Run_Edit_Copy from '../views/createFX_PG_Run_Edit_Copy.vue'
import createPG_Sheet_Exp_Sql from '../views/createPG_Sheet_Exp_Sql.vue'
import createPBX_PG_DB_Calls_Exp from '../views/createPBX_PG_DB_Calls_Exp.vue'
import createPBX_PG_DB_Calls_Exp_Edit_Copy from '../views/createPBX_PG_DB_Calls_Exp_Edit_Copy.vue'
import createGC_PG_DB_MailList_Exp from '../views/createGC_PG_DB_MailList_Exp.vue'
import createGC_PG_DB_MailList_Exp_Edit_Copy from '../views/createGC_PG_DB_MailList_Exp_Edit_Copy.vue'
import createGC_PG_DB_UserActive_Exp from '../views/createGC_PG_DB_UserActive_Exp.vue'
import createYANDEX_PG_DB_Direct_Exp from '../views/createYANDEX_PG_DB_Direct_Exp.vue'
import createYANDEX_PG_DB_Direct_Exp_Edit_Copy from '../views/createYANDEX_PG_DB_Direct_Exp_Edit_Copy.vue'
import createVK1_PG_DB_Ads_Exp from '../views/createVK1_PG_DB_Ads_Exp.vue'
import createVK1_PG_DB_Ads_Exp_Edit_Copy from '../views/createVK1_PG_DB_Ads_Exp_Edit_Copy.vue'
import createVK2_PG_DB_Ads_Exp from '../views/createVK2_PG_DB_Ads_Exp.vue'
import createVK2_PG_DB_Ads_Exp_Edit_Copy from '../views/createVK2_PG_DB_Ads_Exp_Edit_Copy.vue'
import createGADS_PG_DB_Exp from '../views/createGADS_PG_DB_Exp.vue'
import createGADS_PG_DB_Exp_Edit_Copy from '../views/createGADS_PG_DB_Exp_Edit_Copy.vue'
import notificationAddEdit from '../views/notificationAddEdit.vue'
import otherProjectsAddEdit from '../views/otherProjectsAddEdit.vue'
import createAmo_PG_DB_Contacts_Exp from '../views/createAmo_PG_DB_Contacts_Exp.vue'
import createAmo_PG_DB_Contacts_Exp_Edit_Copy from '../views/createAmo_PG_DB_Contacts_Exp_Edit_Copy.vue'
import createAmo_PG_DB_Leads_Exp from '../views/createAmo_PG_DB_Leads_Exp.vue'
import createAmo_PG_DB_Leads_Exp_Edit_Copy from '../views/createAmo_PG_DB_Leads_Exp_Edit_Copy.vue'
import createGC_PG_DB_UsersBuilder_Exp from '../views/createGC_PG_DB_UsersBuilder_Exp.vue'


Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') throw err
  })
}

export default new Router({
  //mode: 'history',
  routes: [
      {
        path: '/',
        name: 'loginForm',
        component: loginForm
      },
      {
          path: '/mainScreen',
          name: 'mainScreen',
          component: mainScreen,
          beforeEnter (to, from, next) {
            //console.log('11 ' + store.getters.checkUser)
            Vue.ls.get(store.getters.UserMail) ? next() : next('/')
          },
          children: [
            
            { 
              path: 'cronJobList',
              component: cronJobList,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'cronJobCreateNew',
              component: cronJobCreateNew,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createSystemJob',
              component: createSystemJob,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createSystemApiJob',
              component: createSystemApiJob,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createSystemPayJob',
              component: createSystemPayJob,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createGC_Sheet_Deals_Exp',
              component: createGC_Sheet_Deals_Exp,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createGC_Sheet_Payments_Exp',
              component: createGC_Sheet_Payments_Exp,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createGC_Sheet_Users_Exp',
              component: createGC_Sheet_Users_Exp,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createBZ365_Sheet_Webinar_Exp',
              component: createBZ365_Sheet_Webinar_Exp,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createGC_Sheet_Deals_Exp_Edit_Copy',
              component: createGC_Sheet_Deals_Exp_Edit_Copy,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createGC_Sheet_Payments_Exp_Edit_Copy',
              component: createGC_Sheet_Payments_Exp_Edit_Copy,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createGC_Sheet_Users_Exp_Edit_Copy',
              component: createGC_Sheet_Users_Exp_Edit_Copy,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createBZ365_Sheet_Webinar_Exp_Edit_Copy',
              component: createBZ365_Sheet_Webinar_Exp_Edit_Copy,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createGC_PG_DB_Deals_Exp',
              component: createGC_PG_DB_Deals_Exp,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createGC_PG_DB_Deals_Exp_Edit_Copy',
              component: createGC_PG_DB_Deals_Exp_Edit_Copy,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createGC_PG_DB_Payments_Exp',
              component: createGC_PG_DB_Payments_Exp,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createGC_PG_DB_Payments_Exp_Edit_Copy',
              component: createGC_PG_DB_Payments_Exp_Edit_Copy,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createGC_PG_DB_Users_Exp',
              component: createGC_PG_DB_Users_Exp,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createGC_PG_DB_Users_Exp_Edit_Copy',
              component: createGC_PG_DB_Users_Exp_Edit_Copy,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createBZ365_PG_DB_Webinar_Exp',
              component: createBZ365_PG_DB_Webinar_Exp,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createBZ365_PG_DB_Webinar_Exp_Edit_Copy',
              component: createBZ365_PG_DB_Webinar_Exp_Edit_Copy,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createPG_Sheet_Exp',
              component: createPG_Sheet_Exp,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createPG_Sheet_Exp_Edit_Copy',
              component: createPG_Sheet_Exp_Edit_Copy,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createFX_PG_Run',
              component: createFX_PG_Run,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createPG_Sheet_Exp_Sql',
              component: createPG_Sheet_Exp_Sql,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createFX_PG_Run_Edit_Copy',
              component: createFX_PG_Run_Edit_Copy,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createPBX_PG_DB_Calls_Exp',
              component: createPBX_PG_DB_Calls_Exp,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createPBX_PG_DB_Calls_Exp_Edit_Copy',
              component: createPBX_PG_DB_Calls_Exp_Edit_Copy,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createGC_PG_DB_MailList_Exp',
              component: createGC_PG_DB_MailList_Exp,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createGC_PG_DB_MailList_Exp_Edit_Copy',
              component: createGC_PG_DB_MailList_Exp_Edit_Copy,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createGC_PG_DB_UserActive_Exp',
              component: createGC_PG_DB_UserActive_Exp,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createGC_PG_DB_UsersBuilder_Exp',
              component: createGC_PG_DB_UsersBuilder_Exp,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createYANDEX_PG_DB_Direct_Exp',
              component: createYANDEX_PG_DB_Direct_Exp,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createYANDEX_PG_DB_Direct_Exp_Edit_Copy',
              component: createYANDEX_PG_DB_Direct_Exp_Edit_Copy,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createVK1_PG_DB_Ads_Exp',
              component: createVK1_PG_DB_Ads_Exp,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createVK1_PG_DB_Ads_Exp_Edit_Copy',
              component: createVK1_PG_DB_Ads_Exp_Edit_Copy,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createVK2_PG_DB_Ads_Exp',
              component: createVK2_PG_DB_Ads_Exp,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createVK2_PG_DB_Ads_Exp_Edit_Copy',
              component: createVK2_PG_DB_Ads_Exp_Edit_Copy,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createGADS_PG_DB_Exp',
              component: createGADS_PG_DB_Exp,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createGADS_PG_DB_Exp_Edit_Copy',
              component: createGADS_PG_DB_Exp_Edit_Copy,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'notificationAddEdit',
              component: notificationAddEdit,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'otherProjectsAddEdit',
              component: otherProjectsAddEdit,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createAmo_PG_DB_Contacts_Exp',
              component: createAmo_PG_DB_Contacts_Exp,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createAmo_PG_DB_Contacts_Exp_Edit_Copy',
              component: createAmo_PG_DB_Contacts_Exp_Edit_Copy,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createAmo_PG_DB_Leads_Exp',
              component: createAmo_PG_DB_Leads_Exp,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createAmo_PG_DB_Leads_Exp_Edit_Copy',
              component: createAmo_PG_DB_Leads_Exp_Edit_Copy,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            }

          ]
      }
  ]
})
