import axios from 'axios'
//import date from 'date-and-time';
//import router from '../router/router.js'
import pgFunctions from './pgFunctions_help'
import store from './store.js'
//const { Buffer } = require("buffer");
import router from '../router/router.js'

export default {
    state: {
        pgFunctions: []
    },
    mutations: {
        loadpgFunctions (state, payload) {
            state.pgFunctions = payload
        }
    },
    actions: {
        async loadpgFunctions ({commit}) {
            //console.log('1');
            commit('clearError')
            commit('setLoading2', true)

            //const dtStart = date.format(dtStart, 'YYYY-MM-DD');
            //const dtEnd = date.format(dtEnd, 'YYYY-MM-DD');
            const pgFunctionsArr = []

            
            let data = []
            
            await axios({
                method: 'POST',
                headers: {
                    'api_key': store.state.CurrentApiKey,
                    'strGlobalProjectName': store.state.GlobalProjectName.trim(),
                    'Content-Type': 'application/json'
                },
                url: store.state.urlCJ + 'getPgFunctionsList',
                data: data

            })
            .then(async (response) => {
                
                //console.log(response.data)

                const pgSQLData = response.data;

                Object.keys(pgSQLData).forEach(key => {
                    const s = pgSQLData[key]
                    //console.log(s)
                    pgFunctionsArr.push(
                        new pgFunctions(
                            s.oid, 
                            s.proname.trim(),
                            s.description == null ? '' : s.description.trim()
                        ) 
                    )
                        
                })
                
                //console.log(pgFunctionsArr)

                
                commit('loadpgFunctions', pgFunctionsArr)
                commit('setLoading2', false)
            })
            .catch((response) => {
                //console.log(response)
                var err = Object.assign({}, response)
                console.log('error', err.response);
                                
                commit('loadpgFunctions', [])
                commit('setLoading2', false)
                commit('setError', response)
                if(err.response.status == '488')
                {
                    router.push('/')
                }
                
            });

        },
        
    },
    
    getters: {
        pgFunctions (state) {
            return state.pgFunctions
        }
    }
}