import axios from 'axios'
//import date from 'date-and-time';
//import router from '../router/router.js'
import pgColumnsTables from './pgColumnsTables_help'
import store from './store.js'
//const { Buffer } = require("buffer");
import router from '../router/router.js'

export default {
    state: {
        pgColumnsTables: []
    },
    mutations: {
        loadpgColumnsTables (state, payload) {
            state.pgColumnsTables = payload
        }
    },
    actions: {
        async loadpgColumnsTables ({commit}, { strTableName }) {
            //console.log('1');
            commit('clearError')
            commit('setLoading2', true)

            //const dtStart = date.format(dtStart, 'YYYY-MM-DD');
            //const dtEnd = date.format(dtEnd, 'YYYY-MM-DD');
            const pgColumnsTablesArr = []

            
            let data = []
            
            await axios({
                method: 'POST',
                headers: {
                    'api_key': store.state.CurrentApiKey,
                    'strGlobalProjectName': store.state.GlobalProjectName.trim(),
                    'TableName': strTableName,
                    'Content-Type': 'application/json'
                },
                url: store.state.urlCJ + 'getpgColumnsTableListBuilder',
                data: data

            })
            .then(async (response) => {
                
                //console.log(response.data)

                const pgSQLData = response.data;

                Object.keys(pgSQLData).forEach(key => {
                    const s = pgSQLData[key]
                    //console.log(s)
                    pgColumnsTablesArr.push(
                        new pgColumnsTables(
                            s.column_name.trim(), 
                            s.data_type.trim()
                        ) 
                    )
                        
                })
                
                //console.log(pgColumnsTablesArr)

                
                commit('loadpgColumnsTables', pgColumnsTablesArr)
                commit('setLoading2', false)
            })
            .catch((response) => {
                //console.log(response)
                var err = Object.assign({}, response)
                console.log('error', err.response);
                                
                commit('loadpgColumnsTables', [])
                commit('setLoading2', false)
                commit('setError', response)

                if(err.response.status == '488')
                {
                    router.push('/')
                }
                
            });

        },
        
    },
    
    getters: {
        pgColumnsTables (state) {
            return state.pgColumnsTables
        }
    }
}