import axios from 'axios'
//import router from '../router/router.js'
import telegramSubscribers from './telegramSubscribers_help'
import store from './store.js'
import router from '../router/router.js'

export default {
    state: {
        telegramSubscribers: []
    },
    mutations: {
        loadtelegramSubscribers (state, payload) {
            state.telegramSubscribers = Array.isArray(payload) ? payload : [];
        }
    },
    actions: {
        async loadtelegramSubscribers ({commit}) {
            //console.log('strCronProjectUUID');
            commit('clearError')
            commit('setLoading2', true)

            //const dtStart = date.format(dtStart, 'YYYY-MM-DD');
            //const dtEnd = date.format(dtEnd, 'YYYY-MM-DD');
            const telegramSubscribersArr = []

            
            let data = []
            
            await axios({
                method: 'POST',
                headers: {
                    'api_key': store.state.CurrentApiKey,
                    'CronProjUUID': store.getters.CronProjectUUID.trim(),
                    'Content-Type': 'application/json'
                },
                url: store.state.urlCJ + 'getTelegramSubscribers',
                data: data

            })
            .then(async (response) => {
                
                //console.log(response.data)

                const pgSQLData = response.data;

                Object.keys(pgSQLData).forEach(key => {
                    const s = pgSQLData[key]
                    
                    //console.log(s)
                    telegramSubscribersArr.push(
                        new telegramSubscribers(
                            s.id, 
                            s.chat_id,
                            s.user_name.trim(),
                            s.telegram_link.trim()
                        ) 
                    )
                        
                })
                //console.log(telegramSubscribersArr)

                
                commit('loadtelegramSubscribers', telegramSubscribersArr)
                commit('setLoading2', false)
            })
            .catch((response) => {
                //console.log(response)
                var err = Object.assign({}, response)
                console.log('error', err.response);
                                
                commit('loadtelegramSubscribers', [])
                commit('setLoading2', false)
                commit('setError', response)
                if(err.response.status == '488')
                {
                    router.push('/')
                }
                
                
            });

        },
        

        
        
    },
    
    getters: {
        telegramSubscribers (state) {
            return state.telegramSubscribers
        }
    }
}