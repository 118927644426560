var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-tabs',{attrs:{"centered":""}},[_c('v-tab',{staticClass:"vtabsvuetyfy",attrs:{"href":"#tab-1"}},[_vm._v(" Список запланированных задач ")]),_c('v-tab',{staticClass:"vtabsvuetyfy",attrs:{"href":"#tab-2"}},[_vm._v(" Список разовых задач ")]),_c('v-tab-item',{attrs:{"value":"tab-1"}},[_c('div',[_c('dxDataGrid',{attrs:{"PageSize":500,"ShowPageSizeSelector":true,"AllowedPageSizes":[ 500, 1000, 2000 ],"ColumnSettingsIN":[
                    { colDataField: 'UUID', colCaption: 'UUID', sortOrder: undefined },
                    { colDataField: 'strSchoolName', colCaption: 'Наименование школы', groupindex: 0, sortOrder: 'asc' },
                    { colDataField: 'jobNameAgenda', colCaption: 'Тип',  groupindex: 1, sortOrder: 'asc' },
                    { colDataField: 'strJobTime', colCaption: 'Время выполнения', sortOrder: undefined },
                    { colDataField: 'dateStart', colCaption: 'Начало отгрузки', sortOrder: undefined },
                    { colDataField: 'strJobRepeat', colCaption: 'Повтор', sortOrder: undefined },
                    { colDataField: 'strJobName', colCaption: 'Наименование', sortOrder: 'asc', visible: true },
                    { colDataField: 'strJobUserComment', colCaption: 'Комментарий', sortOrder: undefined, visible: true },
                    { colDataField: 'intJobActive', colCaption: 'Выполняется', sortOrder: undefined, visible: true },
                    { colDataField: 'intLitePro', colCaption: 'LitePro', sortOrder: undefined, visible: false },
            ],"HeaderText":"Запущенные процессы","HeaderIcon":"mdi-update","EditedItem":{ strSchoolName: '' },"DefaultItem":{ strSchoolName: '' },"AddFields":[
                                //{ model: 'strSchoolName', label: 'Наименование', readonly: false, typeField: 'text', dialog: 'Добавить, Редактировать', sizeField: 'xs12', counterField: '', maskField: '' }
                            ],"EditedFields":[
                                //{ model: 'strSchoolName', label: 'Наименование', readonly: false, typeField: 'text', dialog: 'Добавить, Редактировать', sizeField: 'xs12', counterField: '', maskField: '' }
                            ],"EndPoint":"/test","ParentParams":{ client: this.userID },"GroupPanelVisible":true,"HeaderFilterVisible":false,"FilterRowVisible":false,"SearchVisible":false,"SearchWidth":160,"SummaryVisible":false,"BtnRefresh":true,"BtnAdd":true,"BtnEdit":true,"BtnDelete":true,"BtnShipper":false,"MaxWidth":800,"MaxGridWidth":'max-width: 90%'}})],1)]),_c('v-tab-item',{attrs:{"value":"tab-2"}},[_c('div',[_c('dxDataGridA',{attrs:{"PageSize":50,"ShowPageSizeSelector":true,"AllowedPageSizes":[ 50, 100, 200 ],"ColumnSettingsIN":[
                    { colDataField: 'UUID', colCaption: 'UUID', sortOrder: undefined },
                    { colDataField: 'strSchoolName', colCaption: 'Наименование школы', sortOrder: 'asc' },
                    { colDataField: 'strJobTime', colCaption: 'Время выполнения', sortOrder: undefined },
                    { colDataField: 'strJobRepeat', colCaption: 'Тип задания', sortOrder: undefined },
                    { colDataField: 'strJobName', colCaption: 'Наименование', sortOrder: 'asc', visible: true }
            ],"HeaderText":"Разовые задачи","HeaderIcon":"mdi-update","EditedItem":{ strSchoolName: '' },"DefaultItem":{ strSchoolName: '' },"AddFields":[
                                //{ model: 'strSchoolName', label: 'Наименование', readonly: false, typeField: 'text', dialog: 'Добавить, Редактировать', sizeField: 'xs12', counterField: '', maskField: '' }
                            ],"EditedFields":[
                                //{ model: 'strSchoolName', label: 'Наименование', readonly: false, typeField: 'text', dialog: 'Добавить, Редактировать', sizeField: 'xs12', counterField: '', maskField: '' }
                            ],"EndPoint":"/test","ParentParams":{ client: this.userID },"GroupPanelVisible":true,"HeaderFilterVisible":false,"FilterRowVisible":false,"SearchVisible":false,"SearchWidth":160,"SummaryVisible":false,"BtnRefresh":true,"BtnAdd":true,"BtnEdit":true,"BtnDelete":true,"BtnShipper":false,"MaxWidth":800,"MaxGridWidth":'max-width: 90%'}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }