<template>
    <v-alert 
      dense 
      text 
      type="info" 
      dismissible
      :value="warningZone"
      class="pa-1 ma-1"
    >
      <b>Сервис недоступен!</b><br> Производятся технические работы.<br> Пожалуйста не выполняйте никаких операций в приложении, дождитесь завершения сервисного обслуживания.<br> Это сообщение закроется автоматически как только сервис восстановится!<br><br>
      {{ isUsingSSE ? '' : `Следующая проверка состояния чрез ${Math.max(nextCheckIn, 0)} сек.` }}
    </v-alert>
  </template>
  
  <script>
  import store from '../store/store.js'
  
  export default {
    name: "checkStatus",
    data() {
      return {
        warningZone: false,
        eventSource: null,
        checkInterval: null,
        nextCheckIn: 120,
        isUsingSSE: true,
      }
    },
    mounted() {
      this.startMonitoring()
    },
    beforeDestroy() {
      this.stopMonitoring()
    },
    methods: {
      startMonitoring() {
        this.startEventSource()
      },
      stopMonitoring() {
        if (this.eventSource) {
          this.eventSource.close()
        }
        this.clearCheckInterval()
      },
      clearCheckInterval() {
        if (this.checkInterval) {
          clearInterval(this.checkInterval)
          this.checkInterval = null
        }
      },
      startEventSource() {
        const url = `${store.state.urlCJ}st_bc8f9daa_b8b0?api_key=${store.state.CurrentApiKey}`;
        //console.log('Попытка подключения EventSource:', url);
        
        this.eventSource = new EventSource(url);
        
        this.eventSource.onmessage = (event) => {
            //console.log('Получено сообщение от EventSource:', event.data);
            const data = JSON.parse(event.data);
            this.warningZone = data.status !== 'OK';
            this.isUsingSSE = true;
            this.clearCheckInterval();
        };
        
        this.eventSource.onerror = (error) => {
            console.error('Ошибка EventSource:', error);
            this.warningZone = true;
            this.eventSource.close();
            this.isUsingSSE = false;
            this.startPeriodicCheck();
        };

        /* this.eventSource.onopen = () => {
            console.log('EventSource соединение открыто');
        }; */
      },
      startPeriodicCheck() {
        this.clearCheckInterval()
        this.nextCheckIn = 120
        this.checkInterval = setInterval(() => {
          this.nextCheckIn--
          if (this.nextCheckIn <= 0) {
            this.checkServerStatus()
          }
        }, 1000)
      },
      async checkServerStatus() {
            console.log('Начало проверки статуса сервера');
            try {
                const url = `${store.state.urlCJ}st_bc8f9daa_b8b0?api_key=${store.state.CurrentApiKey}`;
                //console.log('URL для проверки:', url);

                const controller = new AbortController();
                const timeoutId = setTimeout(() => controller.abort(), 10000);

                try {
                const response = await fetch(url, { 
                    method: 'HEAD',
                    signal: controller.signal
                });

                clearTimeout(timeoutId);

                //console.log('Получен ответ:', response);
                //console.log('Статус ответа:', response.status);

                if (response.ok) {
                    console.log('Соединение восстановлено');
                    this.warningZone = false;
                    this.isUsingSSE = true;
                    this.startEventSource();
                    this.clearCheckInterval();
                } else {
                    console.log('Сервер ответил, но статус не OK');
                    this.warningZone = true;
                    this.isUsingSSE = false;
                    this.startPeriodicCheck();
                }
                } catch (fetchError) {
                if (fetchError.name === 'AbortError') {
                    console.log('Запрос был отменен по таймауту');
                } else {
                    console.error('Ошибка при выполнении fetch:', fetchError);
                }
                this.warningZone = true;
                this.isUsingSSE = false;
                this.startPeriodicCheck();
                }
            } catch (error) {
                console.error('Неожиданная ошибка в checkServerStatus:', error);
                this.warningZone = true;
                this.isUsingSSE = false;
                this.startPeriodicCheck();
            }
        }
    }
  }
  </script>