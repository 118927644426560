export default class pgFunctions {
    constructor (
        arguments_name, 
        arguments_type,
        arguments_value
    ) {
      this.arguments_name = arguments_name
      this.arguments_type = arguments_type
      this.arguments_value = arguments_value
    }
  }