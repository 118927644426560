<template>
    <v-card class="mx-auto px-6 py-8" max-width="70%" flat>
      <v-card>
        <v-card-title class="bg-amber-lighten-1">
          <h2 class="me-4 font-weight-light">
            Уведомления
          </h2>
        </v-card-title>
        <v-card-text>
          Подключить уведомления Telegram
        </v-card-text>
      </v-card>
      <br>
      <v-card flat class="mx-auto px-6 py-1">
        <v-container>
          <v-row justify="space-between" align="center">
            <v-col cols="auto">
              <v-btn
                color="primary"
                :href="telegramLink"
                target="_blank"
              >
                <v-icon left>mdi-message-cog-outline</v-icon>
                Подключить Телеграм
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn
                color="secondary"
                @click="updateSubscribersList"
              >
                Обновить список
              </v-btn>
            </v-col>
          </v-row>
        
          <v-data-table
            :headers="headers"
            :items="subscribers"
            class="mt-4"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                color="error"
                small
                @click="openDeleteDialog(item)"
              >
                Удалить
              </v-btn>
            </template>
          </v-data-table>
        </v-container>
        <!-- Диалоговое окно подтверждения удаления -->
        <v-dialog v-model="deleteDialog" max-width="400px">
            <v-card>
                <v-card-title class="headline">Подтверждение удаления</v-card-title>
                <v-card-text>
                    Вы желаете удалить пользователя {{ selectedUser.telegram_link }} из подписки?
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDeleteDialog">Нет</v-btn>
                    <v-btn color="blue darken-1" text @click="confirmDelete">Да</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
      </v-card>
    </v-card>
  </template>
  
  <script>
import { mapGetters } from 'vuex';
import axios from 'axios'
import store from '../store/store.js'

export default {
  data() {
    return {
      telegramLink: 'https://t.me/analyticsnode_bot?start='+this.$store.getters.CronProjectUUID.trim(),
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Chat ID', value: 'chat_id' },
        { text: 'Имя пользователя', value: 'user_name' },
        { text: 'Ссылка Telegram', value: 'telegram_link' },
        { text: 'Действия', value: 'actions', sortable: false }
      ],
      deleteDialog: false,
      selectedUser: {},
    }
  },
  computed: {
    ...mapGetters(['telegramSubscribers']),
    subscribers() {
      //console.log('telegramSubscribers from state:', this.telegramSubscribers);
      return Array.isArray(this.telegramSubscribers) ? this.telegramSubscribers : [];
    }
  },
  methods: {
    async updateSubscribersList() {
      try {
        await this.$store.dispatch('loadtelegramSubscribers');
      } catch (error) {
        console.error('Error updating subscribers list:', error);
        // Здесь вы можете показать уведомление пользователю об ошибке
      }
    },
    openDeleteDialog(item) {
      this.selectedUser = item;
      this.deleteDialog = true;
    },
    closeDeleteDialog() {
      this.deleteDialog = false;
      this.selectedUser = {};
    },
    async confirmDelete() {
      try {
        await this.deleteSubscriber(this.selectedUser.id);
        this.closeDeleteDialog();
        // Обновляем список после удаления
        await this.updateSubscribersList();
      } catch (error) {
        console.error('Error deleting subscriber:', error);
        // Здесь вы можете показать уведомление пользователю об ошибке
      }
    },
    async deleteSubscriber(id) {
      //console.log('Удаление подписчика с ID:', id);

      let data = []

      await axios({
        method: 'POST',
        headers: {
            'api_key': store.state.CurrentApiKey,
            'subscriberID': id,
            'Content-Type': 'application/json'
        },
        url: store.state.urlCJ + 'deleteTelegramSubscribers',
        data: data

    })
    .then((response) => {
        //console.log(response)
        if(response.data == "OK"){
            this.updateSubscribersList();
        }
    })
    .catch((response) => {
        //console.log(response)
        let err = Object.assign({}, response)
        console.log('error', err.response);
                        
        if(err.response.status == '488')
        {
            this.$router.push('/')
        }
    });
    }
  },
  created() {
    //console.log('Component created, calling updateSubscribersList');
    this.updateSubscribersList();
  }
}
</script>