<template>
    <div>
      <v-card
        class="d-flex align-content-start flex-wrap pa-2"
        color="white"
        flat
        tile
        min-height="344"
        v-if="$store.getters.IsAdmin === 1"
      >
        <v-card class="ma-2" max-width="344">
            <v-img src="../assets/systemtask.png" height="200px"></v-img>

            <v-card-title>Системная задача</v-card-title>
            <v-card-subtitle>Удаление отработанных разовых задачь</v-card-subtitle>

            <v-card-actions>
                <v-btn color="primary" @click="createSystemJob()">Создать</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
            
        </v-card>
        <v-card class="ma-2" max-width="344">
            <v-img src="../assets/systemtask.png" height="200px"></v-img>

            <v-card-title>Системная задача</v-card-title>
            <v-card-subtitle>Генерация АПИ Ключей для сервера</v-card-subtitle>

            <v-card-actions>
                <v-btn color="primary" @click="createSystemApiJob()">Создать</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
            
        </v-card>
        <v-card class="ma-2" max-width="344">
            <v-img src="../assets/systemtask.png" height="200px"></v-img>

            <v-card-title>Системная задача</v-card-title>
            <v-card-subtitle>Проверка Активных неоплаченных задач</v-card-subtitle>

            <v-card-actions>
                <v-btn color="primary" @click="createSystemPayJob()">Создать</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
            
        </v-card>
      </v-card>
      <v-card
        class="d-flex align-content-start flex-wrap pa-2"
        color="white"
        flat
        tile
        min-height="344"
      >
        <v-card class="ma-2" max-width="344">
            <v-img src="../assets/gc_google.png" height="200px"></v-img>

            <v-card-title>Заказы</v-card-title>
            <v-card-subtitle>Из GetCourse в Google таблицы.</v-card-subtitle>

            <v-card-actions>
              <v-tooltip bottom :disabled="!isButtonDisabledLite">
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on">
                    <v-btn
                      color="primary"
                      @click="createGC_Sheet_Deals_Exp()"
                      :disabled="isButtonDisabledLite"
                      v-bind="attrs"
                    >
                      Создать
                    </v-btn>
                  </span>
                </template>
                {{ tooltipTextLite }}
              </v-tooltip>
              <v-spacer></v-spacer>
            </v-card-actions>
            
        </v-card>
        <v-card class="ma-2" max-width="344">
            <v-img src="../assets/gc_google.png" height="200px"></v-img>

            <v-card-title>Платежи</v-card-title>
            <v-card-subtitle>Из GetCourse в Google таблицы.</v-card-subtitle>

            <!-- <v-card-actions>
                <v-btn color="primary" @click="createGC_Sheet_Payments_Exp()">Создать</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions> -->
            <v-card-actions>
              <v-tooltip bottom :disabled="!isButtonDisabledLite">
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on">
                    <v-btn
                      color="primary"
                      @click="createGC_Sheet_Payments_Exp()"
                      :disabled="isButtonDisabledLite"
                      v-bind="attrs"
                    >
                      Создать
                    </v-btn>
                  </span>
                </template>
                {{ tooltipTextLite }}
              </v-tooltip>
              <v-spacer></v-spacer>
            </v-card-actions>
            
        </v-card>
        <v-card class="ma-2" max-width="344">
            <v-img src="../assets/gc_google.png" height="200px"></v-img>

            <v-card-title>Пользователи</v-card-title>
            <v-card-subtitle>Из GetCourse в Google таблицы.</v-card-subtitle>

            <!-- <v-card-actions>
                <v-btn color="primary" @click="createGC_Sheet_Users_Exp()">Создать</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions> -->
            <v-card-actions>
              <v-tooltip bottom :disabled="!isButtonDisabledLite">
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on">
                    <v-btn
                      color="primary"
                      @click="createGC_Sheet_Users_Exp()"
                      :disabled="isButtonDisabledLite"
                      v-bind="attrs"
                    >
                      Создать
                    </v-btn>
                  </span>
                </template>
                {{ tooltipTextLite }}
              </v-tooltip>
              <v-spacer></v-spacer>
            </v-card-actions>
            
        </v-card>
        <v-card class="ma-2" max-width="344">
            <v-img src="../assets/bizon_google.png" height="200px"></v-img>

            <v-card-title>Bizon 365</v-card-title>
            <v-card-subtitle>Отчет по вебинарам из Bizon 365 в Google таблицы.</v-card-subtitle>

            <!-- <v-card-actions>
                <v-btn color="primary" @click="createBZ365_Sheet_Webinar_Exp()">Создать</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions> -->
            <v-card-actions>
              <v-tooltip bottom :disabled="!isButtonDisabledLite">
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on">
                    <v-btn
                      color="primary"
                      @click="createBZ365_Sheet_Webinar_Exp()"
                      :disabled="isButtonDisabledLite"
                      v-bind="attrs"
                    >
                      Создать
                    </v-btn>
                  </span>
                </template>
                {{ tooltipTextLite }}
              </v-tooltip>
              <v-spacer></v-spacer>
            </v-card-actions>
            
        </v-card>
      </v-card>
      <v-card
        class="d-flex align-content-start flex-wrap pa-2"
        color="white"
        flat
        tile
        min-height="344"
      >
        <v-card class="ma-2" max-width="344">
            <v-img src="../assets/gc_pg.png" height="200px"></v-img>

            <v-card-title>Заказы</v-card-title>
            <v-card-subtitle>Из GetCourse в PostgreSQL.</v-card-subtitle>

           <!--  <v-card-actions>
                <v-btn color="primary" @click="createGC_PG_DB_Deals_Exp()">Создать</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions> -->
            <v-card-actions>
              <v-tooltip bottom :disabled="!isButtonDisabledPro">
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on">
                    <v-btn
                      color="primary"
                      @click="createGC_PG_DB_Deals_Exp()"
                      :disabled="isButtonDisabledPro"
                      v-bind="attrs"
                    >
                      Создать
                    </v-btn>
                  </span>
                </template>
                {{ tooltipTextPro }}
              </v-tooltip>
              <v-spacer></v-spacer>
            </v-card-actions>
            
        </v-card>

        <v-card class="ma-2" max-width="344">
            <v-img src="../assets/gc_pg.png" height="200px"></v-img>

            <v-card-title>Платежи</v-card-title>
            <v-card-subtitle>Из GetCourse в PostgreSQL.</v-card-subtitle>

            <!-- <v-card-actions>
                <v-btn color="primary" @click="createGC_PG_DB_Payments_Exp()">Создать</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions> -->
            <v-card-actions>
              <v-tooltip bottom :disabled="!isButtonDisabledPro">
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on">
                    <v-btn
                      color="primary"
                      @click="createGC_PG_DB_Payments_Exp()"
                      :disabled="isButtonDisabledPro"
                      v-bind="attrs"
                    >
                      Создать
                    </v-btn>
                  </span>
                </template>
                {{ tooltipTextPro }}
              </v-tooltip>
              <v-spacer></v-spacer>
            </v-card-actions>
            
        </v-card>
        
        <v-card class="ma-2" max-width="344">
            <v-img src="../assets/gc_pg.png" height="200px"></v-img>

            <v-card-title>Пользователи</v-card-title>
            <v-card-subtitle>Из GetCourse в PostgreSQL.</v-card-subtitle>

            <!-- <v-card-actions>
                <v-btn color="primary" @click="createGC_PG_DB_Users_Exp()">Создать</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions> -->
            <v-card-actions>
              <v-tooltip bottom :disabled="!isButtonDisabledPro">
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on">
                    <v-btn
                      color="primary"
                      @click="createGC_PG_DB_Users_Exp()"
                      :disabled="isButtonDisabledPro"
                      v-bind="attrs"
                    >
                      Создать
                    </v-btn>
                  </span>
                </template>
                {{ tooltipTextPro }}
              </v-tooltip>
              <v-spacer></v-spacer>
            </v-card-actions>
            
        </v-card>

        <v-card class="ma-2" max-width="344">
            <v-img src="../assets/gc_pg.png" height="200px"></v-img>

            <v-card-title>Рассылки</v-card-title>
            <v-card-subtitle>Из GetCourse в PostgreSQL.</v-card-subtitle>

            <!-- <v-card-actions>
                <v-btn color="primary" @click="createGC_PG_DB_MailList_Exp()">Создать</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions> -->
            <v-card-actions>
              <v-tooltip bottom :disabled="!isButtonDisabledPro">
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on">
                    <v-btn
                      color="primary"
                      @click="createGC_PG_DB_MailList_Exp()"
                      :disabled="isButtonDisabledPro"
                      v-bind="attrs"
                    >
                      Создать
                    </v-btn>
                  </span>
                </template>
                {{ tooltipTextPro }}
              </v-tooltip>
              <v-spacer></v-spacer>
            </v-card-actions>
            
        </v-card>

        <v-card class="ma-2" max-width="344">
            <v-img src="../assets/gc_pg.png" height="200px"></v-img>

            <v-card-title>Активные пользователи</v-card-title>
            <v-card-subtitle>Из GetCourse в PostgreSQL.</v-card-subtitle>

            <!-- <v-card-actions>
                <v-btn color="primary" @click="createGC_PG_DB_UserActive_Exp()">Создать</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions> -->
            <v-card-actions>
              <v-tooltip bottom :disabled="!isButtonDisabledPro">
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on">
                    <v-btn
                      color="primary"
                      @click="createGC_PG_DB_UserActive_Exp()"
                      :disabled="isButtonDisabledPro"
                      v-bind="attrs"
                    >
                      Создать
                    </v-btn>
                  </span>
                </template>
                {{ tooltipTextPro }}
              </v-tooltip>
              <v-spacer></v-spacer>
            </v-card-actions>
            
        </v-card>

        <v-card class="ma-2" max-width="344">
            <v-img src="../assets/gc_pg.png" height="200px"></v-img>

            <v-card-title>Выборка Геткурса</v-card-title>
            <v-card-subtitle>Конструктор запросов пользователей.</v-card-subtitle>

            <!-- <v-card-actions>
                <v-btn color="primary" @click="createGADS_PG_DB_Ads_Exp()">Создать</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions> -->
            <v-card-actions>
              <v-tooltip bottom :disabled="!isButtonDisabledPro">
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on">
                    <v-btn
                      color="primary"
                      @click="createGC_PG_DB_UsersBuilder_Exp()"
                      :disabled="isButtonDisabledPro"
                      v-bind="attrs"
                    >
                      Создать
                    </v-btn>
                  </span>
                </template>
                {{ tooltipTextPro }}
              </v-tooltip>
              <v-spacer></v-spacer>
            </v-card-actions>
            
        </v-card>

        <v-card class="ma-2" max-width="344">
            <v-img src="../assets/bizon_pg.png" height="200px"></v-img>

            <v-card-title>Bizon 365</v-card-title>
            <v-card-subtitle>Из Bizon 365 в PostgreSQL.</v-card-subtitle>

            <!-- <v-card-actions>
                <v-btn color="primary" @click="createGC_PG_DB_BZ365_Exp()">Создать</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions> -->
            <v-card-actions>
              <v-tooltip bottom :disabled="!isButtonDisabledPro">
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on">
                    <v-btn
                      color="primary"
                      @click="createGC_PG_DB_BZ365_Exp()"
                      :disabled="isButtonDisabledPro"
                      v-bind="attrs"
                    >
                      Создать
                    </v-btn>
                  </span>
                </template>
                {{ tooltipTextPro }}
              </v-tooltip>
              <v-spacer></v-spacer>
            </v-card-actions>
            
        </v-card>

        <v-card class="ma-2" max-width="344">
            <v-img src="../assets/pg_gs.png" height="200px"></v-img>

            <v-card-title>PostgreSQL</v-card-title>
            <v-card-subtitle>Данные из PostgreSQL (View) в Google таблицы.</v-card-subtitle>

            <!-- <v-card-actions>
                <v-btn color="primary" @click="createPG_Sheet_Exp()">Создать</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions> -->
            <v-card-actions>
              <v-tooltip bottom :disabled="!isButtonDisabledPro">
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on">
                    <v-btn
                      color="primary"
                      @click="createPG_Sheet_Exp()"
                      :disabled="isButtonDisabledPro"
                      v-bind="attrs"
                    >
                      Создать
                    </v-btn>
                  </span>
                </template>
                {{ tooltipTextPro }}
              </v-tooltip>
              <v-spacer></v-spacer>
            </v-card-actions>
            
        </v-card>

        <v-card class="ma-2" max-width="344">
            <v-img src="../assets/pg_gs.png" height="200px"></v-img>

            <v-card-title>PostgreSQL Конструктор</v-card-title>
            <v-card-subtitle>Данные из PostgreSQL в Google таблицы.</v-card-subtitle>

            <!-- <v-card-actions>
                <v-btn color="primary" @click="createPG_Sheet_Exp_Sql()">Создать</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions> -->
            <v-card-actions>
              <v-tooltip bottom :disabled="!isButtonDisabledPro">
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on">
                    <v-btn
                      color="primary"
                      @click="createPG_Sheet_Exp_Sql()"
                      :disabled="isButtonDisabledPro"
                      v-bind="attrs"
                    >
                      Создать
                    </v-btn>
                  </span>
                </template>
                {{ tooltipTextPro }}
              </v-tooltip>
              <v-spacer></v-spacer>
            </v-card-actions>
            
        </v-card>

        <v-card class="ma-2" max-width="344">
            <v-img src="../assets/fx_pg.png" height="200px"></v-img>

            <v-card-title>Функции PostgreSQL</v-card-title>
            <v-card-subtitle>Запустить функцию в PostgreSQL.</v-card-subtitle>

            <!-- <v-card-actions>
                <v-btn color="primary" @click="createFX_PG_Run()">Создать</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions> -->
            <v-card-actions>
              <v-tooltip bottom :disabled="!isButtonDisabledPro">
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on">
                    <v-btn
                      color="primary"
                      @click="createFX_PG_Run()"
                      :disabled="isButtonDisabledPro"
                      v-bind="attrs"
                    >
                      Создать
                    </v-btn>
                  </span>
                </template>
                {{ tooltipTextPro }}
              </v-tooltip>
              <v-spacer></v-spacer>
            </v-card-actions>
            
        </v-card>

      </v-card>

      <v-card
        class="d-flex align-content-start flex-wrap pa-2"
        color="white"
        flat
        tile
        min-height="344"
      >
        <v-card class="ma-2" max-width="344">
            <v-img src="../assets/pbx_pg.png" height="200px"></v-img>

            <v-card-title>Online PBX</v-card-title>
            <v-card-subtitle>Из Online PBX в PostgreSQL.</v-card-subtitle>

            <!-- <v-card-actions>
                <v-btn color="primary" @click="createPBX_PG_DB_Calls_Exp()">Создать</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions> -->
            <v-card-actions>
              <v-tooltip bottom :disabled="!isButtonDisabledPro">
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on">
                    <v-btn
                      color="primary"
                      @click="createPBX_PG_DB_Calls_Exp()"
                      :disabled="isButtonDisabledPro"
                      v-bind="attrs"
                    >
                      Создать
                    </v-btn>
                  </span>
                </template>
                {{ tooltipTextPro }}
              </v-tooltip>
              <v-spacer></v-spacer>
            </v-card-actions>
            
        </v-card>

      </v-card>
      
      <v-card
        class="d-flex align-content-start flex-wrap pa-2"
        color="white"
        flat
        tile
        min-height="344"
      >
        <v-card class="ma-2" max-width="344">
            <v-img src="../assets/ydirect_pg.png" height="200px"></v-img>

            <v-card-title>Яндекс Директ</v-card-title>
            <v-card-subtitle>Из Яндекс Директ в PostgreSQL.</v-card-subtitle>

            <!-- <v-card-actions>
                <v-btn color="primary" @click="createYANDEX_PG_DB_Direct_Exp()">Создать</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions> -->
            <v-card-actions>
              <v-tooltip bottom :disabled="!isButtonDisabledPro">
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on">
                    <v-btn
                      color="primary"
                      @click="createYANDEX_PG_DB_Direct_Exp()"
                      :disabled="isButtonDisabledPro"
                      v-bind="attrs"
                    >
                      Создать
                    </v-btn>
                  </span>
                </template>
                {{ tooltipTextPro }}
              </v-tooltip>
              <v-spacer></v-spacer>
            </v-card-actions>
            
        </v-card>

        <v-card class="ma-2" max-width="344">
            <v-img src="../assets/vk1_pg.png" height="200px"></v-img>

            <v-card-title>ВК Реклама 1.0</v-card-title>
            <v-card-subtitle>Из ВК Рекламы в PostgreSQL.</v-card-subtitle>

            <!-- <v-card-actions>
                <v-btn color="primary" @click="createVK1_PG_DB_Ads_Exp()">Создать</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions> -->
            <v-card-actions>
              <v-tooltip bottom :disabled="!isButtonDisabledPro">
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on">
                    <v-btn
                      color="primary"
                      @click="createVK1_PG_DB_Ads_Exp()"
                      :disabled="isButtonDisabledPro"
                      v-bind="attrs"
                    >
                      Создать
                    </v-btn>
                  </span>
                </template>
                {{ tooltipTextPro }}
              </v-tooltip>
              <v-spacer></v-spacer>
            </v-card-actions>
            
        </v-card>

        <v-card class="ma-2" max-width="344">
            <v-img src="../assets/vk1_pg.png" height="200px"></v-img>

            <v-card-title>ВК Реклама 2.0</v-card-title>
            <v-card-subtitle>Из ВК Рекламы в PostgreSQL.</v-card-subtitle>

            <!-- <v-card-actions>
                <v-btn color="primary" @click="createVK2_PG_DB_Ads_Exp()">Создать</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions> -->
            <v-card-actions>
              <v-tooltip bottom :disabled="!isButtonDisabledPro">
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on">
                    <v-btn
                      color="primary"
                      @click="createVK2_PG_DB_Ads_Exp()"
                      :disabled="isButtonDisabledPro"
                      v-bind="attrs"
                    >
                      Создать
                    </v-btn>
                  </span>
                </template>
                {{ tooltipTextPro }}
              </v-tooltip>
              <v-spacer></v-spacer>
            </v-card-actions>
            
        </v-card>

        <v-card class="ma-2" max-width="344">
            <v-img src="../assets/gads_pg.png" height="200px"></v-img>

            <v-card-title>Google ADS</v-card-title>
            <v-card-subtitle>Из Google ADS в PostgreSQL.</v-card-subtitle>

            <!-- <v-card-actions>
                <v-btn color="primary" @click="createGADS_PG_DB_Ads_Exp()">Создать</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions> -->
            <v-card-actions>
              <v-tooltip bottom :disabled="$store.getters.IsAdmin !== 0">
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on">
                    <v-btn
                      color="primary"
                      @click="createGADS_PG_DB_Ads_Exp()"
                      :disabled="$store.getters.IsAdmin === 0"
                      v-bind="attrs"
                    >
                      Создать
                    </v-btn>
                  </span>
                </template>
                Функционал в разработке
              </v-tooltip>
              <v-spacer></v-spacer>
            </v-card-actions>
            
        </v-card>

      </v-card>

      <v-card
        class="d-flex align-content-start flex-wrap pa-2"
        color="white"
        flat
        tile
        min-height="344"
      >
        <v-card class="ma-2" max-width="344">
            <v-img src="../assets/amo_pg.png" height="200px"></v-img>

            <v-card-title>Контакты, Менеджеры</v-card-title>
            <v-card-subtitle>Из AmoCrm в PostgreSQL.</v-card-subtitle>

            <!-- <v-card-actions>
                <v-btn color="primary" @click="createPBX_PG_DB_Calls_Exp()">Создать</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions> -->
            <v-card-actions>
              <v-tooltip bottom :disabled="!isButtonDisabledPro">
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on">
                    <v-btn
                      color="primary"
                      @click="createAmo_PG_DB_Contacts_Exp()"
                      :disabled="isButtonDisabledPro"
                      v-bind="attrs"
                    >
                      Создать
                    </v-btn>
                  </span>
                </template>
                {{ tooltipTextPro }}
              </v-tooltip>
              <v-spacer></v-spacer>
            </v-card-actions>
            
        </v-card>

        <v-card class="ma-2" max-width="344">
            <v-img src="../assets/amo_pg.png" height="200px"></v-img>

            <v-card-title>Сделки, Воронки</v-card-title>
            <v-card-subtitle>Из AmoCrm в PostgreSQL.</v-card-subtitle>

            <!-- <v-card-actions>
                <v-btn color="primary" @click="createPBX_PG_DB_Calls_Exp()">Создать</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions> -->
            <v-card-actions>
              <v-tooltip bottom :disabled="!isButtonDisabledPro">
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on">
                    <v-btn
                      color="primary"
                      @click="createAmo_PG_DB_Leads_Exp()"
                      :disabled="isButtonDisabledPro"
                      v-bind="attrs"
                    >
                      Создать
                    </v-btn>
                  </span>
                </template>
                {{ tooltipTextPro }}
              </v-tooltip>
              <v-spacer></v-spacer>
            </v-card-actions>
            
        </v-card>

      </v-card>

    </div>
  </template>

<script>

import store from '../store/store.js'

export default {
  data: () => ({
    show: false,
  }),
  methods: {
      async updateUserAccessList() {
        try {
          const settingUsr = {
              id: store.getters.CronProjectUUID.trim()
          }
          await this.$store.dispatch('loadotherProjectsById', settingUsr);
        } catch (error) {
          console.error('Error updating otherprojects list:', error);
        }
      },
      createSystemJob: function() {
        this.$router.push('/mainScreen/createSystemJob')
      },
      createSystemApiJob: function() {
        this.$router.push('/mainScreen/createSystemApiJob')
      },
      createSystemPayJob: function() {
        this.$router.push('/mainScreen/createSystemPayJob')
      },
      createGC_Sheet_Deals_Exp: function() {
        this.$router.push('/mainScreen/createGC_Sheet_Deals_Exp')
      },
      createGC_Sheet_Payments_Exp: function() {
        this.$router.push('/mainScreen/createGC_Sheet_Payments_Exp')
      },
      createGC_Sheet_Users_Exp: function() {
        this.$router.push('/mainScreen/createGC_Sheet_Users_Exp')
      },
      createBZ365_Sheet_Webinar_Exp: function() {
        this.$router.push('/mainScreen/createBZ365_Sheet_Webinar_Exp')
      },
      createGC_PG_DB_Deals_Exp: function() {
        this.$router.push('/mainScreen/createGC_PG_DB_Deals_Exp')
      },
      createGC_PG_DB_Payments_Exp: function() {
        this.$router.push('/mainScreen/createGC_PG_DB_Payments_Exp')
      },
      createGC_PG_DB_Users_Exp: function() {
        this.$router.push('/mainScreen/createGC_PG_DB_Users_Exp')
      },
      createGC_PG_DB_BZ365_Exp: function() {
        this.$router.push('/mainScreen/createBZ365_PG_DB_Webinar_Exp')
      },
      createPG_Sheet_Exp: function() {
        this.$router.push('/mainScreen/createPG_Sheet_Exp')
      },
      createPG_Sheet_Exp_Sql: function() {
        this.$router.push('/mainScreen/createPG_Sheet_Exp_Sql')
      },
      createFX_PG_Run: function() {
        this.$router.push('/mainScreen/createFX_PG_Run')
      },
      createPBX_PG_DB_Calls_Exp: function() {
        this.$router.push('/mainScreen/createPBX_PG_DB_Calls_Exp')
      },
      createGC_PG_DB_MailList_Exp: function() {
        this.$router.push('/mainScreen/createGC_PG_DB_MailList_Exp')
      },
      createYANDEX_PG_DB_Direct_Exp: function() {
        this.$router.push('/mainScreen/createYANDEX_PG_DB_Direct_Exp')
      },
      createVK1_PG_DB_Ads_Exp: function() {
        this.$router.push('/mainScreen/createVK1_PG_DB_Ads_Exp')
      },
      createVK2_PG_DB_Ads_Exp: function() {
        this.$router.push('/mainScreen/createVK2_PG_DB_Ads_Exp')
      },
      createGADS_PG_DB_Ads_Exp: function() {
        this.$router.push('/mainScreen/createGADS_PG_DB_Exp')
      },
      createGC_PG_DB_UserActive_Exp: function() {
        this.$router.push('/mainScreen/createGC_PG_DB_UserActive_Exp')
      },
      createGC_PG_DB_UsersBuilder_Exp: function() {
        this.$router.push('/mainScreen/createGC_PG_DB_UsersBuilder_Exp')
      },
      createAmo_PG_DB_Contacts_Exp: function() {
        this.$router.push('/mainScreen/createAmo_PG_DB_Contacts_Exp')
      },
      createAmo_PG_DB_Leads_Exp: function() {
        this.$router.push('/mainScreen/createAmo_PG_DB_Leads_Exp')
      },
  },
  computed: {
    isButtonDisabledLite() {
      const hasLiteAccess  = store.getters.LiteAccess !== 0;
      const hasUnlimitedAccess  = store.getters.UnlimitedAccess === 1;
      const dateAccess  = store.getters.DateAccess.toString().substr(0,10);
      
      let isDateValid = false;
      if (dateAccess !== null) {
        const currentDate = new Date();
        const accessDate = new Date(dateAccess);
        
        // Сравниваем только год, месяц и день
        isDateValid = 
          accessDate.getFullYear() > currentDate.getFullYear() || 
          (accessDate.getFullYear() === currentDate.getFullYear() && 
          (accessDate.getMonth() > currentDate.getMonth() || 
            (accessDate.getMonth() === currentDate.getMonth() && 
            accessDate.getDate() >= currentDate.getDate())));
        
        //console.log(isDateValid)

        //console.log(`${accessDate.getFullYear()}-${String(accessDate.getMonth() + 1).padStart(2, '0')}-${String(accessDate.getDate()).padStart(2, '0')}`);
        //console.log(`${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`);
      }

      // Доступ открыт, если есть Lite доступ И (есть безлимитный доступ ИЛИ дата действительна)
      const hasAccess = hasLiteAccess && (hasUnlimitedAccess || isDateValid);

      //console.log('hasLiteAccess:', hasLiteAccess);
      //console.log('hasUnlimitedAccess:', hasUnlimitedAccess);
      //console.log('isDateValid:', isDateValid);
      //console.log('hasAccess:', hasAccess);

      return !hasAccess;
    },
    tooltipTextLite() {
      if (store.getters.LiteAccess === 0) {
        return 'Функционал доступен на Lite подписке';
      } else if (store.getters.UnlimitedAccess !== 1) {
        const dateAccess = store.getters.DateAccess;

        let isDateValid = false;
        if (dateAccess !== null) {
          const currentDate = new Date();
          const accessDate = new Date(dateAccess);
          
          // Сравниваем только год, месяц и день
          isDateValid = 
            accessDate.getFullYear() > currentDate.getFullYear() || 
            (accessDate.getFullYear() === currentDate.getFullYear() && 
            (accessDate.getMonth() > currentDate.getMonth() || 
              (accessDate.getMonth() === currentDate.getMonth() && 
              accessDate.getDate() >= currentDate.getDate())));
          
        }
        
        if (!isDateValid) {
          return 'Срок доступа истек';
        }
      }
      return '';
    },
    isButtonDisabledPro() {
      const hasProAccess  = store.getters.ProAccess !== 0;
      const hasUnlimitedAccess  = store.getters.UnlimitedAccess === 1;
      const dateAccess  = store.getters.DateAccess;
      //console.log(dateAccess)
      
      let isDateValid = false;
      if (dateAccess !== null) {
        const currentDate = new Date();
        const accessDate = new Date(dateAccess);
        
        // Сравниваем только год, месяц и день
        isDateValid = 
          accessDate.getFullYear() > currentDate.getFullYear() || 
          (accessDate.getFullYear() === currentDate.getFullYear() && 
          (accessDate.getMonth() > currentDate.getMonth() || 
            (accessDate.getMonth() === currentDate.getMonth() && 
            accessDate.getDate() >= currentDate.getDate())));
        
        //console.log(isDateValid)

        //console.log(`${accessDate.getFullYear()}-${String(accessDate.getMonth() + 1).padStart(2, '0')}-${String(accessDate.getDate()).padStart(2, '0')}`);
        //console.log(`${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`);
      }

      // Доступ открыт, если есть Lite доступ И (есть безлимитный доступ ИЛИ дата действительна)
      const hasAccess = hasProAccess && (hasUnlimitedAccess || isDateValid);

      //console.log('hasLiteAccess:', hasLiteAccess);
      //console.log('hasUnlimitedAccess:', hasUnlimitedAccess);
      //console.log('isDateValid:', isDateValid);
      //console.log('hasAccess:', hasAccess);

      return !hasAccess;
    },
    tooltipTextPro() {
      if (store.getters.ProAccess === 0) {
        return 'Функционал доступен на Pro подписке';
      } else if (store.getters.UnlimitedAccess !== 1) {
        const dateAccess = store.getters.DateAccess.toString().substr(0,10);
        if (dateAccess === null || new Date(dateAccess) < new Date()) {
          return 'Срок доступа истек';
        }
      }
      return '';
    }

  },
  mounted() {
    store.commit('SetProjectCOPY', 0)
    store.commit('SetProjectEDIT', 0)
    //console.log(store.getters.DateAccess)
    
  },
  created() {
    this.updateUserAccessList();
    //store.commit('SetDateAccess', '2024-08-17')
    //store.commit('SetUnlimitedAccess', 0)
  }

}
</script>