export default class telegramSubscribers {
    constructor (
      id, 
      chat_id,
      user_name,
      telegram_link
    ) {
      this.id = id
      this.chat_id = chat_id
      this.user_name = user_name
      this.telegram_link = telegram_link
    }
  }