<template>
    
    <v-tabs  centered>
    
    <v-tab href="#tab-1" class="vtabsvuetyfy">
        Список запланированных задач
    </v-tab>

    <v-tab href="#tab-2" class="vtabsvuetyfy">
        Список разовых задач
    </v-tab>
        
    <v-tab-item value="tab-1">
    <div>
        <dxDataGrid
                :PageSize = "500"
                :ShowPageSizeSelector = "true"
                :AllowedPageSizes = "[ 500, 1000, 2000 ]"
                :ColumnSettingsIN = "[
                        { colDataField: 'UUID', colCaption: 'UUID', sortOrder: undefined },
                        { colDataField: 'strSchoolName', colCaption: 'Наименование школы', groupindex: 0, sortOrder: 'asc' },
                        { colDataField: 'jobNameAgenda', colCaption: 'Тип',  groupindex: 1, sortOrder: 'asc' },
                        { colDataField: 'strJobTime', colCaption: 'Время выполнения', sortOrder: undefined },
                        { colDataField: 'dateStart', colCaption: 'Начало отгрузки', sortOrder: undefined },
                        { colDataField: 'strJobRepeat', colCaption: 'Повтор', sortOrder: undefined },
                        { colDataField: 'strJobName', colCaption: 'Наименование', sortOrder: 'asc', visible: true },
                        { colDataField: 'strJobUserComment', colCaption: 'Комментарий', sortOrder: undefined, visible: true },
                        { colDataField: 'intJobActive', colCaption: 'Выполняется', sortOrder: undefined, visible: true },
                        { colDataField: 'intLitePro', colCaption: 'LitePro', sortOrder: undefined, visible: false },
                ]"

                HeaderText = "Запущенные процессы"
                HeaderIcon = "mdi-update"
                :EditedItem = "{ strSchoolName: '' }"
                :DefaultItem = "{ strSchoolName: '' }"
                :AddFields = "[
                                    //{ model: 'strSchoolName', label: 'Наименование', readonly: false, typeField: 'text', dialog: 'Добавить, Редактировать', sizeField: 'xs12', counterField: '', maskField: '' }
                                ]"
                :EditedFields = "[
                                    //{ model: 'strSchoolName', label: 'Наименование', readonly: false, typeField: 'text', dialog: 'Добавить, Редактировать', sizeField: 'xs12', counterField: '', maskField: '' }
                                ]"
                EndPoint="/test"
                :ParentParams="{ client: this.userID }"
                :GroupPanelVisible = "true"
                :HeaderFilterVisible = "false"
                :FilterRowVisible = "false"
                :SearchVisible = "false"
                :SearchWidth = "160"
                :SummaryVisible = "false"
                :BtnRefresh="true"
                :BtnAdd="true"
                :BtnEdit="true"
                :BtnDelete="true"
                :BtnShipper="false"
                :MaxWidth="800"
                :MaxGridWidth="'max-width: 90%'"
        ></dxDataGrid>
        
    </div>
    </v-tab-item>

    <v-tab-item value="tab-2">
        <div>
        <dxDataGridA
                :PageSize = "50"
                :ShowPageSizeSelector = "true"
                :AllowedPageSizes = "[ 50, 100, 200 ]"
                :ColumnSettingsIN = "[
                        { colDataField: 'UUID', colCaption: 'UUID', sortOrder: undefined },
                        { colDataField: 'strSchoolName', colCaption: 'Наименование школы', sortOrder: 'asc' },
                        { colDataField: 'strJobTime', colCaption: 'Время выполнения', sortOrder: undefined },
                        { colDataField: 'strJobRepeat', colCaption: 'Тип задания', sortOrder: undefined },
                        { colDataField: 'strJobName', colCaption: 'Наименование', sortOrder: 'asc', visible: true }
                ]"

                HeaderText = "Разовые задачи"
                HeaderIcon = "mdi-update"
                :EditedItem = "{ strSchoolName: '' }"
                :DefaultItem = "{ strSchoolName: '' }"
                :AddFields = "[
                                    //{ model: 'strSchoolName', label: 'Наименование', readonly: false, typeField: 'text', dialog: 'Добавить, Редактировать', sizeField: 'xs12', counterField: '', maskField: '' }
                                ]"
                :EditedFields = "[
                                    //{ model: 'strSchoolName', label: 'Наименование', readonly: false, typeField: 'text', dialog: 'Добавить, Редактировать', sizeField: 'xs12', counterField: '', maskField: '' }
                                ]"
                EndPoint="/test"
                :ParentParams="{ client: this.userID }"
                :GroupPanelVisible = "true"
                :HeaderFilterVisible = "false"
                :FilterRowVisible = "false"
                :SearchVisible = "false"
                :SearchWidth = "160"
                :SummaryVisible = "false"
                :BtnRefresh="true"
                :BtnAdd="true"
                :BtnEdit="true"
                :BtnDelete="true"
                :BtnShipper="false"
                :MaxWidth="800"
                :MaxGridWidth="'max-width: 90%'"
        ></dxDataGridA>
        
    </div>
    </v-tab-item>
    
    </v-tabs>
</template>

<script>
    import dxDataGrid from '@/components/dxDataGridF.vue'
    import dxDataGridA from '@/components/dxDataGridFA.vue'

    export default {
        name: "projectForm",
        data() {
            return {
                userID: this.$store.getters.UserId,
                selectedItemC: null,
            }
        },
        watch: {
            selectedItemC(){
                this.selectedContract = this.selectedItemC
                //console.log(this.selectedContract);
            }
        },
        computed: {
        },
        beforeMount() {
            this.selectedItemC = 0
        },
        mounted() {
            this.$store.dispatch('loadpgTables')
            this.$store.dispatch('loadpgGoogleServiceAccounts')
        },
        components:{
            dxDataGrid,
            dxDataGridA
        },
        methods: {
            async updateUserAccessList() {
                try {
                const settingUsr = {
                    id: this.$store.getters.CronProjectUUID.trim()
                }
                await this.$store.dispatch('loadotherProjectsById', settingUsr);
                } catch (error) {
                console.error('Error updating otherprojects list:', error);
                }
            },
        },
        created() {
            this.updateUserAccessList();
        }
    }
</script>


