<template>
  <v-card class="mx-auto px-6 py-8" max-width="90%" flat>
    <v-card>
      <v-card-title class="bg-amber-lighten-1">
        <h2 class="me-4 font-weight-light">
          Другие проекты
        </h2>
      </v-card-title>
      <v-card-text>
        Управление другими проектами планировщика
      </v-card-text>
    </v-card>
    <br>
    <v-card flat class="mx-auto px-6 py-1">
      <v-container>
        <v-row justify="space-between" align="center">
          <v-col cols="auto">
            <v-btn
              color="primary"
              @click="addProject"
              class="mr-2"
            >
              Добавить
            </v-btn>
            <v-btn
              color="warning"
              @click="editProject"
              class="mr-2"
              :disabled="!selectedProject"
            >
              Изменить
            </v-btn>
            <v-btn
              color="info"
              @click="changePassword"
              class="mr-2"
              :disabled="!selectedProject"
            >
              Сменить пароль
            </v-btn>
            <v-btn
              color="error"
              @click="deleteProject"
              class="mr-2"
              :disabled="!selectedProject"
            >
              Удалить
            </v-btn>
            <v-btn
              color="secondary"
              @click="updateSubscribersList"
            >
              Обновить список
            </v-btn>
          </v-col>
        </v-row>
      
        <div class="table-container">
          <v-data-table
            :headers="headers"
            :items="otherprojects"
            :items-per-page="10"
            class="mt-4 custom-table"
            :style="{ minWidth: tableMinWidth + 'px' }"
            :height="400"
            fixed-header
          >
            <template v-slot:item="{ item }">
              <tr 
                :class="{ 'selected-row': item.strProjectUUID === selectedProjectId }"
                @click="selectProject(item)"
              >
                <td>{{ item.id }}</td>
                <td>{{ item.strUserName }}</td>
                <td>
                  <v-icon :color="item.intAdmin ? 'green' : 'red'">
                    {{ item.intAdmin ? 'mdi-check' : 'mdi-close' }}
                  </v-icon>
                </td>
                <td>
                  <v-icon :color="item.intLiteAccess ? 'green' : 'red'">
                    {{ item.intLiteAccess ? 'mdi-check' : 'mdi-close' }}
                  </v-icon>
                </td>
                <td>
                  <v-icon :color="item.intProAccess ? 'green' : 'red'">
                    {{ item.intProAccess ? 'mdi-check' : 'mdi-close' }}
                  </v-icon>
                </td>
                <td>
                  <v-icon :color="item.intUnlimitedAccess ? 'green' : 'red'">
                    {{ item.intUnlimitedAccess ? 'mdi-check' : 'mdi-close' }}
                  </v-icon>
                </td>
                <td>{{ item.dtDateLastPay }}</td>
                <td>{{ item.dtDateAccess }}</td>
                <td>{{ item.strProjectName }}</td>
                <td>{{ item.strProjectUUID }}</td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-container>
    </v-card>
    <!-- Добавляем модальное окно -->
    <v-dialog v-model="addDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Добавить новый проект</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>
              <v-row dense>
                <v-col cols="12" class="py-1">
                  <v-text-field
                    v-model="newProject.un"
                    :rules="[v => !!v || 'Имя пользователя обязательно']"
                    label="Имя пользователя*"
                    required
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-1">
                  <v-text-field
                    v-model="newProject.p"
                    :rules="[v => !!v || 'Пароль обязателен']"
                    label="Пароль*"
                    type="password"
                    required
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="3" class="py-1">
                  <v-checkbox v-model="newProject.intAdmin" label="Админ" dense></v-checkbox>
                </v-col>
                <v-col cols="3" class="py-1">
                  <v-checkbox v-model="newProject.intLiteAccess" label="Lite" dense></v-checkbox>
                </v-col>
                <v-col cols="3" class="py-1">
                  <v-checkbox v-model="newProject.intProAccess" label="Pro" dense></v-checkbox>
                </v-col>
                <v-col cols="3" class="py-1">
                  <v-checkbox v-model="newProject.intUnlimitedAccess" label="Безлимит" dense></v-checkbox>
                </v-col>
                <v-col cols="12" class="py-1">
                  <v-menu
                    v-model="dateLastPayMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="newProject.dtDateLastPay"
                        :rules="[v => !!v || 'Дата платежа обязательна']"
                        label="Дата платежа*"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        required
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="newProject.dtDateLastPay"
                      @input="dateLastPayMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" class="py-1">
                  <v-menu
                    v-model="dateAccessMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="newProject.dtDateAccess"
                        :rules="[v => !!v || 'Дата доступа обязательна']"
                        label="Доступ До*"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        required
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="newProject.dtDateAccess"
                      @input="dateAccessMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" class="py-1">
                  <v-text-field
                    v-model="newProject.strProjectName"
                    :rules="[v => !!v || 'Имя проекта обязательно']"
                    label="Имя проекта*"
                    required
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <small>*обязательные поля</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeAddDialog">Отмена</v-btn>
          <v-btn color="blue darken-1" text @click="saveNewProject">Добавить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Диалог удаления v-dialog -->
    <v-dialog v-model="deleteDialog" max-width="400px">
      <v-card>
        <v-card-title class="text-h5">Подтверждение удаления</v-card-title>
        <v-card-text>
          Вы действительно хотите удалить проект?
          <br>
          ИД Проекта: {{ selectedProject ? selectedProject.strProjectUUID : '' }}
          <br>
          Имя пользователя: {{ selectedProject ? selectedProject.strUserName : '' }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDeleteDialog">Отмена</v-btn>
          <v-btn color="red darken-1" text @click="confirmDeleteProject">Удалить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Добавляем диалог редактирования -->
    <v-dialog v-model="editDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Редактировать проект</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="editForm" v-model="editValid" lazy-validation>
            <v-container>
              <v-row dense>
                <v-col cols="3" class="py-1">
                  <v-checkbox v-model="editedProject.intAdmin" label="Админ" dense></v-checkbox>
                </v-col>
                <v-col cols="3" class="py-1">
                  <v-checkbox v-model="editedProject.intLiteAccess" label="Lite" dense></v-checkbox>
                </v-col>
                <v-col cols="3" class="py-1">
                  <v-checkbox v-model="editedProject.intProAccess" label="Pro" dense></v-checkbox>
                </v-col>
                <v-col cols="3" class="py-1">
                  <v-checkbox v-model="editedProject.intUnlimitedAccess" label="Безлимит" dense></v-checkbox>
                </v-col>
                <v-col cols="12" class="py-1">
                  <v-menu
                    v-model="editDateLastPayMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedProject.dtDateLastPay"
                        label="Дата платежа"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="editedProject.dtDateLastPay"
                      @input="editDateLastPayMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" class="py-1">
                  <v-menu
                    v-model="editDateAccessMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedProject.dtDateAccess"
                        label="Доступ До"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="editedProject.dtDateAccess"
                      @input="editDateAccessMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeEditDialog">Отмена</v-btn>
          <v-btn color="blue darken-1" text @click="saveEditedProject">Сохранить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Добавляем диалог смены пароля -->
    <v-dialog v-model="changePasswordDialog" max-width="400px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Сменить пароль</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="changePasswordForm" v-model="changePasswordValid" lazy-validation>
            <v-text-field
              v-model="changePasswordData.oldPassword"
              :rules="[v => !!v || 'Старый пароль обязателен']"
              label="Старый пароль"
              type="password"
              required
            ></v-text-field>
            <v-text-field
              v-model="changePasswordData.newPassword"
              :rules="[v => !!v || 'Новый пароль обязателен']"
              label="Новый пароль"
              type="password"
              required
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeChangePasswordDialog">Отмена</v-btn>
          <v-btn color="blue darken-1" text @click="confirmChangePassword">ОК</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Добавляем диалог успешной смены пароля -->
    <v-dialog v-model="passwordChangedDialog" max-width="300px">
      <v-card>
        <v-card-title class="text-h5">{{ passwordChangedDialogTitle }}</v-card-title>
        <v-card-text>{{ passwordChangedDialogText }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="passwordChangedDialog = false">ОК</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { customAlphabet } from 'nanoid/non-secure'

export default {
  data() {
    return {
      headers: [
        { text: 'ID', value: 'id', width: 50 },
        { text: 'Имя пользователя', value: 'strUserName', width: 150 },
        { text: 'Админ', value: 'intAdmin', width: 50 },
        { text: 'Доступ Lite', value: 'intLiteAccess', width: 40 },
        { text: 'Доступ Pro', value: 'intProAccess', width: 40 },
        { text: 'Безлимит', value: 'intUnlimitedAccess', width: 40 },
        { text: 'Дата платежа', value: 'dtDateLastPay', width: 110 },
        { text: 'Доступ До', value: 'dtDateAccess', width: 110 },
        { text: 'Имя проекта', value: 'strProjectName', width: 100 },
        { text: 'ИД Проекта', value: 'strProjectUUID', width: 250 }
      ],
      selectedProjectId: null,
      selectedProjectNumericId: null,
      valid: true,
      addDialog: false,
      dateLastPayMenu: false,
      dateAccessMenu: false,
      newProject: {
        un: '',
        p: '',
        intAdmin: false,
        intLiteAccess: false,
        intProAccess: false,
        intUnlimitedAccess: false,
        dtDateLastPay: '',
        dtDateAccess: '',
        strProjectName: ''
      },
      deleteDialog: false,
      editDialog: false,
      editValid: true,
      editDateLastPayMenu: false,
      editDateAccessMenu: false,
      editedProject: {
        id: '',
        intAdmin: false,
        intLiteAccess: false,
        intProAccess: false,
        intUnlimitedAccess: false,
        dtDateLastPay: '',
        dtDateAccess: ''
      },
      changePasswordDialog: false,
      changePasswordValid: true,
      changePasswordData: {
        oldPassword: '',
        newPassword: ''
      },
      passwordChangedDialog: false,
      passwordChangedDialogTitle: 'Успешно',
      passwordChangedDialogText: '',
    }
  },
  computed: {
    ...mapGetters(['otherProjects']),
    otherprojects() {
      return Array.isArray(this.otherProjects) ? this.otherProjects : [];
    },
    tableMinWidth() {
      return this.headers.reduce((total, header) => total + (header.width || 100), 0);
    },
    selectedProject() {
      return this.otherprojects.find(project => project.strProjectUUID === this.selectedProjectId);
    },
  },
  methods: {
    async updateSubscribersList() {
      try {
        await this.$store.dispatch('loadotherProjects');
      } catch (error) {
        console.error('Error updating otherprojects list:', error);
      }
    },
    selectProject(project) {
      this.selectedProjectId = project.strProjectUUID;
      this.selectedProjectNumericId = project.id;
    },
    formatDate(date) {
      return date.getUTCFullYear() + '-' + 
        String(date.getUTCMonth() + 1).padStart(2, '0') + '-' +
        String(date.getUTCDate()).padStart(2, '0') + ' ' +
        String(date.getUTCHours()).padStart(2, '0') + ':' +
        String(date.getUTCMinutes()).padStart(2, '0');
    },
    getDateMinusMinutes(date, minutes) {
      return new Date(date.getTime() - minutes * 60000);
    },
    addProject() {
      // Implement add project logic
      //console.log('Add project');
      

      this.addDialog = true;
    },
    closeAddDialog() {
      this.addDialog = false;
      
    },
    resetNewProject() {
      this.newProject = {
        un: '',
        p: '',
        intAdmin: false,
        intLiteAccess: false,
        intProAccess: false,
        intUnlimitedAccess: false,
        dtDateLastPay: '',
        dtDateAccess: '',
        strProjectName: ''
      };
    },
    async saveNewProject() {
      if (this.$refs.form.validate()) {
        this.closeAddDialog();
        let liteUUIDName = this.newProject.strProjectName
        let nanoid = customAlphabet('1234567890abcdef', 10)
        this.newProject.strProjectName = liteUUIDName + '_' + nanoid()

        // Здесь будет логика сохранения нового проекта
        //console.log('Сохранение нового проекта:', this.newProject);
        // TODO: Добавить отправку данных на сервер

        //console.log(this.newProject)

        await this.$store.dispatch('addotherProjects', this.newProject)
        .then(() => {
            //console.log('OK')
            //console.log(this.$store.getters.settings)
            this.resetNewProject();
            this.updateSubscribersList();
        })
        .catch((error) => {
            console.log(error.response.data)
            this.resetNewProject();
            this.updateSubscribersList();
            /* this.errorRu = error.response.data
            if(error.response.data == 'The email address is already in use by another account.')
            {
                this.errorRu = 'Это Имя пользователя уже используется для другого пользователя.'
            }
            this.errorMsg = this.errorRu
            try {
                this.doAlertMessage(true,'','','Ошибка',this.errorMsg)
                //this.alertText = response.response.data.error
            }
            catch (e) {
                this.doAlertMessage(true,'','','Ошибка',e.messageerror)
                //this.alertText = e.messageerror
            } */
        })
      }
    },
    deleteProject() {
      if (this.selectedProject) {
        this.deleteDialog = true;
      }
    },
    closeDeleteDialog() {
      this.deleteDialog = false;
    },
    async confirmDeleteProject() {
      this.closeDeleteDialog();
      // Здесь реализуйте логику удаления проекта
      //console.log('Удаление проекта', this.selectedProjectId);

      const settingDelete = {
          id: this.selectedProjectId
      }

      await this.$store.dispatch('deleteotherProjects', settingDelete)
      .then(() => {
          //console.log('OK')
          //console.log(this.$store.getters.settings)
          
          this.updateSubscribersList();
      })
      .catch((error) => {
          console.log(error.response.data)
          this.updateSubscribersList();
          /* this.errorRu = error.response.data
          if(error.response.data == 'The email address is already in use by another account.')
          {
              this.errorRu = 'Это Имя пользователя уже используется для другого пользователя.'
          }
          this.errorMsg = this.errorRu
          try {
              this.doAlertMessage(true,'','','Ошибка',this.errorMsg)
              //this.alertText = response.response.data.error
          }
          catch (e) {
              this.doAlertMessage(true,'','','Ошибка',e.messageerror)
              //this.alertText = e.messageerror
          } */
      })
      
      
    },
    editProject() {
      if (this.selectedProject) {
        this.editedProject.id = this.selectedProjectId;
        this.editedProject.intAdmin = this.selectedProject.intAdmin;
        this.editedProject.intLiteAccess = this.selectedProject.intLiteAccess;
        this.editedProject.intProAccess = this.selectedProject.intProAccess;
        this.editedProject.intUnlimitedAccess = this.selectedProject.intUnlimitedAccess;
        this.editedProject.dtDateLastPay = this.selectedProject.dtDateLastPay;
        this.editedProject.dtDateAccess = this.selectedProject.dtDateAccess;
        this.editDialog = true;
      }
    },
    closeEditDialog() {
      this.editDialog = false;
      
    },
    resetEditedProject() {
      this.editedProject = {
        id: '',
        intAdmin: false,
        intLiteAccess: false,
        intProAccess: false,
        intUnlimitedAccess: false,
        dtDateLastPay: '',
        dtDateAccess: ''
      };
    },
    async saveEditedProject() {
      if (this.$refs.editForm.validate()) {
        this.closeEditDialog();
        //console.log(this.editedProject)

        // Здесь будет логика сохранения отредактированного проекта
        // TODO: Добавить отправку данных на сервер

        await this.$store.dispatch('updateOtherProject', this.editedProject)
        .then(() => {
          this.resetEditedProject();
          this.updateSubscribersList();
        })
        .catch((error) => {
          console.log(error.response.data);
          this.resetEditedProject();
          this.updateSubscribersList();
        });
      }
    },
    changePassword() {
      if (this.selectedProject) {
        this.changePasswordDialog = true;
      }
    },
    closeChangePasswordDialog() {
      this.changePasswordDialog = false;
      this.resetChangePasswordForm();
    },
    resetChangePasswordForm() {
      this.changePasswordData = {
        oldPassword: '',
        newPassword: ''
      };
      this.$refs.changePasswordForm.resetValidation();
    },
    async confirmChangePassword() {
      if (this.$refs.changePasswordForm.validate()) {
        try {
          // Здесь будет вызов API для смены пароля
          await this.$store.dispatch('changeOtherProjectPassword', {
            projectId: this.selectedProjectId,
            oldPassword: this.changePasswordData.oldPassword,
            newPassword: this.changePasswordData.newPassword
          });
          
          this.closeChangePasswordDialog();
          this.passwordChangedDialogTitle = 'Успешно'
          this.passwordChangedDialogText = 'Пароль успешно изменен!'
          this.passwordChangedDialog = true;
        } catch (error) {
          this.closeChangePasswordDialog();
          this.passwordChangedDialogTitle = 'Ошибка'
          this.passwordChangedDialogText = 'Ошибка изменения пароля пользователя!\n' + error.response.data
          this.passwordChangedDialog = true;
          console.error('Error changing password:', error.response.data);
          // Здесь можно добавить обработку ошибок, например, показать сообщение об ошибке
        }
      }
    },
  },
  created() {
    this.updateSubscribersList();
  }
}
</script>

<style scoped>
.table-container {
  width: 100%;
  overflow-x: auto;
}

.custom-table {
  width: 100%;
}

/* Ensure header stays fixed when scrolling horizontally */
:deep(.v-data-table__wrapper) {
  overflow-x: visible !important;
}

/* Optional: Improve readability with alternating row colors */
:deep(.v-data-table__wrapper tbody tr:nth-of-type(odd)) {
  background-color: rgba(0, 0, 0, 0.05);
}

.selected-row {
  background-color: rgba(0, 0, 255, 0.1) !important;
}
</style>