export default class otherProjects {
    constructor (
      id, 
      strUserName,
      intAdmin,
      intLiteAccess,
      intProAccess,
      intUnlimitedAccess,
      dtDateLastPay,
      dtDateAccess,
      strProjectName,
      strProjectUUID
    ) {
      this.id = id
      this.strUserName = strUserName
      this.intAdmin = intAdmin
      this.intLiteAccess = intLiteAccess
      this.intProAccess = intProAccess
      this.intUnlimitedAccess = intUnlimitedAccess
      this.dtDateLastPay = dtDateLastPay
      this.dtDateAccess = dtDateAccess
      this.strProjectName = strProjectName
      this.strProjectUUID = strProjectUUID
    }
  }