<template>
  <v-card :class="{'and-condition': group.type === 'and', 'or-condition': group.type === 'or'}" class="mb-4">
    <v-card-title>
      <span>{{ group.type === 'and' ? 'И' : 'ИЛИ' }}</span>
      <v-spacer></v-spacer>
      <v-btn color="primary" small class="mr-2" @click="addCondition('and')">И</v-btn>
      <v-btn color="primary" small class="mr-2" @click="addCondition('or')">ИЛИ</v-btn>
    </v-card-title>
    <v-card-text>
      <div v-for="(condition, index) in group.conditions" :key="'condition-' + index" class="mb-4 condition-wrapper">
        <v-card>
          <v-card-text>
            <div class="delete-button-container">
              <v-btn icon small color="error" @click="removeCondition(index)" class="delete-button">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-row dense>
              <v-col cols="12" class="pb-0 mt-4">
                <v-autocomplete
                  v-model="condition.type"
                  :items="conditionTypes"
                  item-text="text"
                  item-value="value"
                  label="Тип условия"
                  outlined
                  dense
                  clearable
                  @input="updateCondition(index, 'type', $event)"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row v-if="condition.type === 'user_last_activity_at' || condition.type === 'user_createdat'" dense>
              <v-col cols="12" class="pb-0">
                <v-select
                  :value="condition.dateType"
                  @input="updateCondition(index, 'dateType', $event)"
                  :items="dateTypes"
                  label="Тип даты"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" v-if="condition.dateType === 'date_range'">
                <v-row>
                  <v-col cols="6">
                    <v-menu
                      v-model="condition.fromMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="condition.from"
                          @input="updateCondition(index, 'from', $event)"
                          label="От"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :value="condition.from"
                        @input="updateCondition(index, 'from', $event); condition.fromMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6">
                    <v-menu
                      v-model="condition.toMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="condition.to"
                          @input="updateCondition(index, 'to', $event)"
                          label="До"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :value="condition.to"
                        @input="updateCondition(index, 'to', $event); condition.toMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" v-if="condition.dateType === 'last_n_days'">
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      :value="condition.fromNDays"
                      @input="updateCondition(index, 'fromNDays', Number($event))"
                      label="От (дней назад)"
                      type="number"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      :value="condition.toNDays"
                      @input="updateCondition(index, 'toNDays', Number($event))"
                      label="До (дней назад)"
                      type="number"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
      <div v-for="(subGroup, index) in group.groups" :key="'group-' + index">
        <condition-group 
          :group="subGroup"
          :is-root="false"
          :block-index="blockIndex"
          :can-remove-block="canRemoveBlock"
          @remove-group="removeSubGroup(index)"
          @update-group="updateSubGroup(index, $event)"
          @add-condition="addSubGroupCondition(index, $event)"
          @add-group="addSubGroupGroup(index, $event)"
        ></condition-group>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ConditionGroup',
  props: {
    group: Object,
    isRoot: Boolean,
    blockIndex: Number,
    canRemoveBlock: {
      type: Function,
      default: () => true
    }
  },
  data() {
    return {
      conditionTypes: [
        { text: 'Дата последней активности', value: 'user_last_activity_at' },
        { text: 'Дата регистрации', value: 'user_createdat' },
      ],
      dateTypes: [
        { text: 'Выбор даты', value: 'date_range' },
        { text: 'N Дней назад', value: 'last_n_days' },
      ],
    };
  },
  methods: {
    addCondition(type) {
      if (type === this.group.type) {
        this.$emit('add-condition', this.createNewCondition());
      } else {
        this.$emit('add-group', {
          type: type,
          conditions: [this.createNewCondition()],
          groups: [],
        });
      }
    },
    removeCondition(index) {
      const newConditions = [...this.group.conditions];
      newConditions.splice(index, 1);
      
      this.updateGroupAfterRemoval(newConditions, this.group.groups);
    },
    removeSubGroup(index) {
      const newGroups = [...this.group.groups];
      newGroups.splice(index, 1);
      
      this.updateGroupAfterRemoval(this.group.conditions, newGroups);
    },
    updateGroupAfterRemoval(conditions, groups) {
      if (conditions.length === 0 && groups.length === 0) {
        if (this.isRoot) {
          this.$emit('check-can-remove-block', this.blockIndex, (canRemove) => {
            if (canRemove) {
              this.$emit('remove-condition', this.blockIndex);
            } else {
              // Если корневой блок нельзя удалить, оставляем его без изменений
              this.$emit('update-group', {
                ...this.group,
              });
            }
          });
        } else {
          // Если это не корневой блок, удаляем его
          this.$emit('remove-group');
        }
      } else {
        // Обновляем группу с новыми условиями и подгруппами
        this.$emit('update-group', {
          ...this.group,
          conditions: conditions,
          groups: groups,
        });
      }
    },
    updateSubGroup(index, updatedGroup) {
      const newGroups = [...this.group.groups];
      newGroups[index] = updatedGroup;
      this.$emit('update-group', {
        ...this.group,
        groups: newGroups,
      });
    },
    addSubGroupCondition(index, newCondition) {
      const newGroups = [...this.group.groups];
      newGroups[index].conditions.push(newCondition);
      this.$emit('update-group', {
        ...this.group,
        groups: newGroups,
      });
    },
    addSubGroupGroup(index, newGroup) {
      const newGroups = [...this.group.groups];
      newGroups[index].groups.push(newGroup);
      this.$emit('update-group', {
        ...this.group,
        groups: newGroups,
      });
    },
    updateCondition(index, field, value) {
      const updatedConditions = [...this.group.conditions];
      updatedConditions[index] = {
        ...updatedConditions[index],
        [field]: value,
      };
      this.$emit('update-group', {
        ...this.group,
        conditions: updatedConditions,
      });
    },
    createNewCondition() {
      return {
        type: 'user_last_activity_at',
        dateType: 'last_n_days',
        from: '',
        to: '',
        fromNDays: 0,
        toNDays: 7,
        fromMenu: false,
        toMenu: false,
      };
    },
  },
};
</script>

<style scoped>
.and-condition {
  border-left: 5px solid blue;
}
.or-condition {
  border-left: 5px solid green;
}
.condition-wrapper {
  position: relative;
}
</style>